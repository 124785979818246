// subCategoryReducers.js
const addSubCategory = (state, { payload }) => {
  if (state.subCategory) {
    state.subCategory.push(payload);
  } else {
    state.subCategory = [payload];
  }
};

const getSubCategory = (state, { payload }) => {
  state.subCategory = payload;
};

export { addSubCategory, getSubCategory };
