import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Box,
} from "@mui/material";
import React from "react";

export function RHFMultiSelect({
  name,
  label,
  placeholder,
  required = false,
  options,
  helperText,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: `Please select at least one option`,
        },
      }}
      defaultValue={[]}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          {label && (
            <InputLabel id={`${name}-label`} size="small">
              {label}
            </InputLabel>
          )}
          <Select
            {...field}
            labelId={`${name}-label`}
            multiple
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={options.find((option) => option.value === value)?.label || value}
                  />
                ))}
              </Box>
            )}
            sx={{
              height: "37px !important",
              "& .MuiSelect-select": {
                height: "37px !important",
                display: "flex",
                alignItems: "center",
              },
            }}
            {...other}
          >
            {placeholder && (
              <MenuItem disabled value="">
                <em>{placeholder}</em>
              </MenuItem>
            )}
            {options &&
              options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#FD8D4C",
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
          </Select>
          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
