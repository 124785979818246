// eslint-disable-next-line react/prop-types
import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Icon } from "@iconify/react";

function RHFImageUpload({ name, label, required = false, sx, defaultValue = null, ...other }) {
  const { control } = useFormContext();
  const [image, setImage] = useState(defaultValue);

  const handleInputChange = (event, onChange) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        setImage(URL.createObjectURL(file));
        onChange(file);
      }
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: `This field is required`,
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <div>
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event) => handleInputChange(event, onChange)}
            // {...other}
          />
          <label htmlFor="image-upload">
            {image ? (
              <div className="mb-4">
                <img src={image} alt="Preview" style={{ width: "350px", height: "200px" }} />
              </div>
            ) : (
              <div className="flex mb-4 justify-center">
                <div className="w-24 h-24 rounded-full border bg-customShadow ">
                  <label className="flex pt-7 justify-center" htmlFor="image-upload">
                    <Icon
                      className="w-9 text-white h-9"
                      icon="material-symbols-light:upload-file-outline-sharp"
                    />
                  </label>
                </div>
              </div>
            )}
          </label>
          {error && <span className="text-red-500 text-sm">{error.message}</span>}
        </div>
      )}
    />
  );
}

export default RHFImageUpload;
