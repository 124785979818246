import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "subComponents/Tables/DataTable";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import authorsTableData from "./data/walletTransTableData";
import { PagesRounded, RoundedCorner, WidthFull } from "@mui/icons-material";
import { useLazyGetWalletTransactionQuery } from "../../redux/api/walletTransactionApi";
import { useDebounce } from "@uidotdev/usehooks";
import useFilter from "utility/useFilter";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
function WalletTransaction() {
  const [walletTransactionList, setWalletTransactionList] = useState([]);
  const [page, setPage] = useState(1);
  const [getWalletTransactions] = useLazyGetWalletTransactionQuery();
  const [searchValue, setSearchValue] = useState();
  const debouncedSearchTerm = useDebounce(searchValue, 800);
  const { filters, setFilters } = useFilter();
  const handleChange = (e, p) => {
    setPage(p);
  };
  useEffect(() => {
    const fetchWalletTransactionsData = async () => {
      const walletTransactionData = await getWalletTransactions(filters);
      if (walletTransactionData?.data?.success)
        setWalletTransactionList(walletTransactionData?.data?.data);
    };
    fetchWalletTransactionsData();
  }, [filters, page]);
  const { columns, rows } = authorsTableData({ walletTransactionList });
  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, search: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, page]);

  return (
    <div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Wallte Transaction
                </MDTypography>
                <MDBox pr={1}>
                  {/* <MDInput
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                    }}
                    label="Search here"
                  /> */}
                  <input
                    className="text-sm p-2 rounded-md w-56"
                    type="text"
                    placeholder="Search By Pandit/Approved By"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={walletTransactionList?.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </div>
  );
}
export default WalletTransaction;
