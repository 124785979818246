// notificationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addLanguage, getLanguage } from "../reducers/languageReducer";

const initialState = {
  language: [],
  error: null,
};

const languageSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addLanguage,
    getLanguage,
  },
});

export const { addLanguage: addLanguageAction, getLanguage: getLanguageAction } =
  languageSlice.actions;
export default languageSlice.reducer;
