import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APP_FIREBASE_API_KEY || "AIzaSyDTcOKx1AgkBMQufI0vduyWlExCJp6FSS8",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "yajya-f3e41.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "yajya-f3e41",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "yajya-f3e41.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSANGING_SENDER_ID || "433316522338",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:433316522338:web:7b8ede8211a77bd364643b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
