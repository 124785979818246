import React from "react";
import DashboardLayout from "subComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "subComponents/Navbars/DashboardNavbar";
import Sidenav from "subComponents/Sidenav";
import Configurator from "subComponents/Configurator";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import routes from "../routes";

const LayoutWrapper = ({ children }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    layout,
  } = controller;

  const handleOnMouseEnter = () => {
    if (miniSidenav) {
      setMiniSidenav(dispatch, false);
    }
  };

  const handleOnMouseLeave = () => {
    if (!miniSidenav) {
      setMiniSidenav(dispatch, true);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  return (
    <div className="bg-bgColor min-h-screen w-full">
      <DashboardLayout>
        <DashboardNavbar />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Yajya Dashboard"
              routes={routes}
            />
            {/* <Configurator />
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="3.25rem"
              height="3.25rem"
              bgColor="white"
              shadow="sm"
              borderRadius="50%"
              position="fixed"
              right="2rem"
              bottom="2rem"
              zIndex={99}
              color="dark"
              sx={{ cursor: "pointer" }}
              onClick={handleConfiguratorOpen}
            >
              <Icon fontSize="small" color="inherit">
                notifications
              </Icon>
            </MDBox> */}
          </>
        )}
        {children}
      </DashboardLayout>
    </div>
  );
};

export default LayoutWrapper;
