import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import YajyaLogo from "../../../assets/images/logos/yajyaLogo.png";
import bgImage from "../../../assets/images/Frame.jpg";
import { useLoginInUserMutation } from "../../../redux/api/authApi";
import { setAuthStateAction, setAuthErrorAction } from "../../../redux/slices/authSlice";

// Validation schema
const schema = yup.object().shape({
  email: yup.string().email("Invalid email format").required("Email is required"),
  password: yup.string().required("Password is required"),
});

function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginInUser] = useLoginInUserMutation();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const deviceId = useSelector((state) => state?.deviceId?.deviceId);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    const loginData = {
      ...data,
      deviceId,
    };
    try {
      const response = await loginInUser(loginData).unwrap();
      const payload = {
        user: response.data.user,
        token: response.data.tokens.access.token,
      };
      dispatch(setAuthStateAction(payload));
      navigate("/dashboard");
    } catch (error) {
      dispatch(setAuthErrorAction(error.data?.message || "Failed to login"));
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          style={{
            boxShadow: "0px 4px 10px rgba(95, 95, 95, 0.2)", // Custom shadow
          }}
        >
          <MDBox variant="gradient" fontWeight="medium" display="flex" justifyContent="center">
            <img className="h-10 flex items-center" src={YajyaLogo} alt="Yajya Logo" />
          </MDBox>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <MDBox mb={2}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <MDBox>
                    <MDInput
                      {...field}
                      type="email"
                      label="Email"
                      fullWidth
                      error={!!errors.email}
                    />
                    {errors.email && <FormHelperText error>{errors.email.message}</FormHelperText>}
                  </MDBox>
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <MDBox>
                    <MDInput
                      {...field}
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.password}
                    />
                    {errors.password && (
                      <FormHelperText error>{errors.password.message}</FormHelperText>
                    )}
                  </MDBox>
                )}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                fullWidth
                type="submit"
                style={{ backgroundColor: "#FE7165", color: "#FFFFFF" }}
              >
                Sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
