// subCategoryReducers.js
const addPanditOnboard = (state, { payload }) => {
  if (state.panditOnboard) {
    state.panditOnboard.push(payload);
  } else {
    state.panditOnboard = [payload];
  }
};

export { addPanditOnboard };
