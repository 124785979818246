import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import PendingReqCard from "layouts/dashboard/components/PendingReq/PendingReqCard.js";

// Data
import cardData from "layouts/dashboard/components/PendingReq/data";
function Projects({ assignData }) {
  const [menu, setMenu] = useState(null);
  const pendingRequestsCount = assignData?.length || 0;
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card
      style={{
        boxShadow: "0px 4px 10px rgba(242, 111, 34, 0.2)",
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Assigning Requests
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <InfoOutlinedIcon
              sx={{
                fontWeight: "bold",
                color: "white",
                mt: -0.5,
              }}
            />
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{pendingRequestsCount} </strong> Pending Requests
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox sx={{ height: "400px", overflow: "auto" }}>
        <PendingReqCard assignData={assignData} />
      </MDBox>
    </Card>
  );
}

export default Projects;
