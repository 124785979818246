// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getDashboard } from "../reducers/dashboardReducer";

const initialState = {
  dashboard: [],
  error: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDashboard,
  },
});

export const { getDashboard: getDashboardAction } = dashboardSlice.actions;
export default dashboardSlice.reducer;
