// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { setAuthState, logout, setAuthError } from "../reducers/authReducer";

const userToken = localStorage.getItem("accessToken");

const initialState = {
  userInfo: null,
  userToken: userToken || null,
  isAuthenticated: !!userToken || false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState,
    logout,
    setAuthError,
  },
});

export const {
  setAuthState: setAuthStateAction,
  logout: logoutAction,
  setAuthError: setAuthErrorAction,
} = authSlice.actions;
export default authSlice.reducer;
