import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "subComponents/Tables/DataTable";
import { useForm, FormProvider } from "react-hook-form";
import { Button, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import notificationTableData from "./data/notificationTableData";
import toast, { Toaster } from "react-hot-toast";
import AddNotification from "./addNotification/index";
import ConfirmDelete from "../../shared/deleteDialog";
import { useDispatch, useSelector } from "react-redux";
import EditNotification from "./addNotification";
import { customToastStyle } from "../../redux/api";
import {
  useUpdateNotificationMutation,
  useLazyGetNotificationQuery,
  useDeleteNotificationMutation,
} from "../../redux/api/notificationApi";
import { getNotificationAction } from "../../redux/slices/notificationSlice";
import useFetchData from "../../utility/useFetchData";
import { useDebounce } from "@uidotdev/usehooks";
import useFilter from "utility/useFilter";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function Notification() {
  const methods = useForm();
  const dispatch = useDispatch();
  // const notificationData = useSelector((state) => state?.notification?.notification);
  const [getNotification] = useLazyGetNotificationQuery();
  // const [deleteNotification] = useDeleteNotificationMutation();
  const [updateNotification] = useUpdateNotificationMutation();
  const [page, setPage] = useState(1);
  const [notificationDialog, setNotificationDialog] = useState(false);
  // const fetchNotificationData = useFetchData(getNotification, getNotificationAction);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  // const [sendNotification, setSendNotification] = useState(null);
  const [notificationList, setNotificationList] = useState([]);
  const [deleteNotification, { isLoading: isDeleteNotificationLoading }] =
    useDeleteNotificationMutation();
  // const [searchValue, setSearchValue] = useState();
  // const { columns, rows } = authorsTableData(
  //   notificationData,
  //   (item) => setEditIndex(item),
  //   (item) => setDeleteIndex(item)
  // );
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const { filters, setFilters } = useFilter();

  const handleOnClick = () => {
    methods.reset();
    setNotificationDialog(true);
  };
  const handleCloseClick = () => {
    setNotificationDialog(false);
    methods.reset();
  };

  const handleCloseDelete = () => {
    setDeleteIndex(null);
  };

  const confirmDeleteHandler = (item) => {
    if (deleteIndex) {
      handleDeleteNotification(deleteIndex);
    }
    setDeleteIndex(null);
  };
  const handleCloseEdit = async (item) => {
    // if (editIndex) {
    //   await updateCategory({ id: item.id, ...data }).unwrap();
    // }
    setEditIndex(null);
  };
  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleDeleteNotification = async (item) => {
    try {
      await deleteNotification(item.id).unwrap();
    } catch (err) {
      console.error("Failed to delete notification:", err);
    }
  };
  useEffect(() => {
    const fetchNotificationData = async () => {
      const notificationData = await getNotification(filters);
      if (notificationData?.data?.success) setNotificationList(notificationData?.data?.data);
    };
    fetchNotificationData();
  }, [filters, isDeleteNotificationLoading, page]);
  const { columns, rows } = notificationTableData(
    notificationList,
    (item) => setEditIndex(item),
    (item) => setDeleteIndex(item)
  );

  useEffect(() => {
    setFilters((prevValue) => ({
      ...prevValue,
      search: debouncedSearchTerm,
      page,
    }));
  }, [debouncedSearchTerm, page]);
  return (
    <FormProvider {...methods}>
      <div className="flex justify-end">
        {" "}
        <Button onClick={handleOnClick} variant="addButton">
          Add Notification
        </Button>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Notification
                </MDTypography>
                <input
                  className="text-sm mr-2 p-2 text-gray-700 rounded-md w-56"
                  type="text"
                  placeholder="Search Here"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {notificationDialog ? (
        <AddNotification
          title="Add Notification"
          setNotificationList={setNotificationList}
          onClose={handleCloseClick}
          open={notificationDialog}
        />
      ) : (
        ""
      )}
      {deleteIndex !== null && (
        <ConfirmDelete
          title=""
          fullMessage={"Are you sure you want to Delete this Notification?"}
          handleClose={handleCloseDelete}
          deleteHandler={confirmDeleteHandler}
          open={deleteIndex !== null}
          setNotificationList={setNotificationList}
        />
      )}
      {editIndex !== null && (
        <EditNotification
          title="Edit Notification"
          onClose={handleCloseEdit}
          setNotificationList={setNotificationList}
          editData={editIndex}
          open={editIndex !== null}
        />
      )}
      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={notificationList.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </FormProvider>
  );
}

export default Notification;
