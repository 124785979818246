// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getWalletTransactions } from "../reducers/walletTransactionReducer";

const initialState = {
  walletTransactions: [],
  error: null,
};

const walletTransactionsSlice = createSlice({
  name: "walletTransactions",
  initialState,
  reducers: {
    getWalletTransactions,
  },
});

export const { getWalletTransactions: getWalletTransactionsAction } =
  walletTransactionsSlice.actions;
export default walletTransactionsSlice.reducer;
