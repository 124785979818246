import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Icon } from "@iconify/react";

import { useVerifyDocumentMutation } from "../../../redux/api/panditsApi";

const VerifyPandit = ({ onClose, open, documents, id, setIsDocumentVerified }) => {
  const { documentVerification } = documents;
  const { handleSubmit } = useFormContext();
  const [verifyDocument] = useVerifyDocumentMutation();
  const [isDeclined, setIsDeclined] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState({
    reason1: false,
    reason2: false,
    reason3: false,
    reason4: false,
  });

  const openPDFInNewWindow = (url) => {
    window.open(url, "_blank");
  };

  const handleSubmitClick = async () => {
    if (isDeclined) {
      const selectedTexts = Object.keys(selectedReasons).filter(
        (reason) => selectedReasons[reason]
      );
    }
    const isDocumentVerify = await verifyDocument({ id, ...documentVerification });
    if (isDocumentVerify?.data?.success) {
      setIsDocumentVerified(isDocumentVerify?.data?.data?.isDocumentVerified);
      onClose();
    }
  };

  const handleDeclineClick = () => {
    setIsDeclined(true);
  };

  const handleReasonChange = (event) => {
    setSelectedReasons({
      ...selectedReasons,
      [event.target.name]: event.target.checked,
    });
  };

  const documentArray = documents?.documentVerification
    ? Object.keys(documents?.documentVerification).map((key) => ({
        name: key,
        url: documents?.documentVerification[key],
      }))
    : [];

  const isDocumentArrayEmpty = !documentArray || documentArray.length === 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: "#FD8D4C", width: "400px", height: "auto" }}>Documents</DialogTitle>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <DialogContent>
          {isDocumentArrayEmpty ? (
            <div className="w-96 mb-2 text-gray-500">
              <p>Pandit did not upload any documents.</p>
            </div>
          ) : (
            documentArray.map((document, index) => (
              <div key={index} className="w-96 mb-2">
                <div className="flex mb-2 items-center">
                  <div className="flex justify-between w-full">
                    <h1 className="text-black">{document?.name}</h1>
                    <div className="flex items-center pt-1">
                      <Icon
                        onClick={() => openPDFInNewWindow(document?.url)}
                        className="text-black w-5 h-5 mr-2 cursor-pointer"
                        icon="mdi:eye"
                      />
                      <a
                        href={document.url}
                        download={`document/${document?.name}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon
                          className="text-black w-5 h-5 cursor-pointer"
                          icon="material-symbols-light:download-sharp"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
          {isDeclined && !isDocumentArrayEmpty && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedReasons.reason1}
                    onChange={handleReasonChange}
                    name="reason1"
                    color="primary"
                  />
                }
                label="Reason 1"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedReasons.reason2}
                    onChange={handleReasonChange}
                    name="reason2"
                    color="primary"
                  />
                }
                label="Reason 2"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedReasons.reason3}
                    onChange={handleReasonChange}
                    name="reason3"
                    color="primary"
                  />
                }
                label="Reason 3"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedReasons.reason4}
                    onChange={handleReasonChange}
                    name="reason4"
                    color="primary"
                  />
                }
                label="Reason 4"
              />
            </div>
          )}
        </DialogContent>
        {!documents?.isDocumentVerified && !isDocumentArrayEmpty && (
          <DialogActions>
            <Button variant="submit" onClick={handleDeclineClick}>
              Decline
            </Button>
            <Button variant="submit" type="submit">
              {isDeclined ? "Submit Decline" : "Approve"}
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

VerifyPandit.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  documents: PropTypes.shape({
    isDocumentVerified: PropTypes.bool,
    documentVerification: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  id: PropTypes.string.isRequired,
  setIsDocumentVerified: PropTypes.func.isRequired,
};

export default VerifyPandit;
