import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "subComponents/Tables/DataTable";
import { useForm, FormProvider } from "react-hook-form";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import samagriTableData from "./data/samagriTableData";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import ConfirmDelete from "../../shared/deleteDialog";
import { useSelector } from "react-redux";
import { useLazyGetSamagriQuery, useDeleteSamagriMutation } from "../../redux/api/samagriApi";
import { getSamagriAction } from "../../redux/slices/samagriSlice";
import useFetchData from "../../utility/useFetchData";
import useFilter from "utility/useFilter";
import { useDebounce } from "@uidotdev/usehooks";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function Samagri() {
  const navigate = useNavigate();
  const samagriData = useSelector((state) => state?.samagri?.samagri);

  const methods = useForm();
  const [getSamagri] = useLazyGetSamagriQuery();
  const [page, setPage] = useState(1);
  // const [deleteSamagri] = useDeleteSamagriMutation();
  // const { columns, rows } = authorsTableData(samagriData, (item) => setDeleteIndex(item));
  const [deleteSamagri, { isLoading: isDeleteSamagriLoading }] = useDeleteSamagriMutation();
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [samagriList, setSamagriList] = useState([]);
  // const [searchValue, setSearchValue] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const { filters, setFilters } = useFilter();

  const handleOnClick = () => {
    navigate("/samagri//add-samagri");
  };

  const handleCloseDelete = () => {
    setDeleteIndex(null);
  };

  const confirmDeleteHandler = (item) => {
    if (deleteIndex) {
      handleDeleteSamagri(deleteIndex);
    }
    setDeleteIndex(null);
  };
  const handleChange = (e, p) => {
    setPage(p);
  };
  // const fetchSamagriData = useFetchData(getSamagri, getSamagriAction);
  // const handleSearchClick = (e) => {
  //   if (e.key === "Enter") {
  //     const value = e.target.value;
  //     setSearchValue(value);
  //   }
  // };
  // useEffect(() => {
  //   fetchSamagriData({ sortBy: "createdAt:desc", plan: searchValue });
  // }, [searchValue, samagriData]);

  const handleDeleteSamagri = async (item) => {
    try {
      await deleteSamagri(item._id).unwrap();
    } catch (err) {
      console.error("Failed to delete samagri:", err);
    }
  };
  useEffect(() => {
    const fetchSamagriData = async () => {
      const samagriData = await getSamagri(filters);
      if (samagriData?.data?.success) setSamagriList(samagriData?.data?.data);
    };
    fetchSamagriData();
  }, [filters, isDeleteSamagriLoading, page]);

  const { columns, rows } = samagriTableData(samagriList, (item) => setDeleteIndex(item));

  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, name: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, page]);

  return (
    <FormProvider {...methods}>
      <div className="flex justify-end">
        <Button variant="addButton" onClick={handleOnClick}>
          Add Samagri
        </Button>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Samagri
                </MDTypography>
                <input
                  className="text-sm p-2 text-gray-700 rounded-md w-56"
                  type="text"
                  placeholder="Search Category or Sub-Category"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {deleteIndex !== null && (
        <ConfirmDelete
          title=""
          fullMessage={"Are you sure you want to Delete this Samagri?"}
          handleClose={handleCloseDelete}
          deleteHandler={confirmDeleteHandler}
          open={deleteIndex !== null}
        />
      )}

      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={samagriList.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </FormProvider>
  );
}

export default Samagri;
