import { apiSlice } from ".";
import URLS from "../constants/api";

export const bannerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addBanner: builder.mutation({
      query: (body) => ({
        url: URLS.BANNER,
        method: "POST",
        body,
      }),
      providesTags: ["Banner"],
    }),
    getBanner: builder.query({
      query: ({ sortBy, limit, name, page }) => {
        let queryString = `${URLS.BANNER}?sortBy=${sortBy}&limit=${limit}`;
        if (name) {
          queryString += `&name=${name}`;
        }
        if (page) {
          queryString += `&page=${page}`;
        }
        return queryString;
      },
      providedTags: ["Banner"],
    }),
    deleteBanner: builder.mutation({
      query: (id) => ({
        url: `${URLS.BANNER}?bannerId=${id}`,
        method: "DELETE",
      }),
      providesTags: ["Banner"],
    }),
    deactivateBanner: builder.mutation({
      query: (id, body) => ({
        url: `${URLS.BANNER}?bannerId=${id}`,
        method: "PATCH",
        body,
      }),

      providesTags: ["Banner"],
    }),
  }),
});

export const {
  useAddBannerMutation,
  useLazyGetBannerQuery,
  useDeleteBannerMutation,
  useDeactivateBannerMutation,
} = bannerApiSlice;
