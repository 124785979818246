import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import Card from "@mui/material/Card";
import { Icon } from "@iconify/react";
import {
  useLazyGetRelevantPanditQuery,
  useAssignPanditMutation,
} from "../../../../../redux/api/bookingApi";

const AssigningDialog = ({ setIsAssigned, open, orderId }) => {
  const [panditList, setPanditList] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // New state to track select/deselect all
  const [getRelevantPandit] = useLazyGetRelevantPanditQuery();
  const [assignPandit, { isLoading: isAssignPanditLoading }] = useAssignPanditMutation();

  const toggleChecked = (index, isChecked) => {
    const updatedCardsData = panditList.map((card, i) =>
      i === index ? { ...card, isChecked } : card
    );
    setPanditList(updatedCardsData);
  };

  const handleSelectAllClick = () => {
    const updatedCardsData = panditList.map((card) => ({
      ...card,
      isChecked: !selectAll,
    }));
    setPanditList(updatedCardsData);
    setSelectAll(!selectAll); // Toggle selectAll state
  };

  const handleAssignPandit = async () => {
    const selectedPanditId = panditList.filter((item) => item.isChecked).map((item) => item.id);
    const payload = {
      panditUserId: selectedPanditId,
      orderId,
      reason: "Assigning in progress",
    };

    const panditAssigning = await assignPandit(payload);

    setIsAssigned(false);
  };

  const handleCancelClick = () => {
    setIsAssigned(false);
  };

  useEffect(() => {
    const fetchPanditData = async () => {
      const panditData = await getRelevantPandit({ orderId });
      if (panditData?.data?.success) setPanditList(panditData?.data?.data);
    };
    fetchPanditData();
  }, [orderId]);

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          color: "#FD8D4C",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1>Assign Pandit</h1>
        <span onClick={handleCancelClick} className="text-black mt-1">
          <Icon className="h-6 w-6" icon="ic:baseline-close" />
        </span>
      </DialogTitle>
      <hr />
      <DialogContent sx={{ width: "500px", height: "350px" }}>
        <div className="flex justify-end overflow-y-scroll">
          <Button onClick={handleSelectAllClick} variant="submit" type="button">
            {selectAll ? "Deselect All" : "Select All"}
          </Button>
        </div>
        {panditList &&
          panditList.map((item, index) => (
            <Card
              key={index}
              style={{
                boxShadow: "0px 4px 10px rgba(242, 111, 34, 0.2)",
                marginTop: "20px",
              }}
              className="inset-0 relative p-3"
            >
              <div className="flex justify-between">
                <div className="flex">
                  <img src={item.profileImage} alt="" className="h-16 w-16 rounded-full" />
                  <div className=" ml-4">
                    <div className="flex justify-between">
                      <h5 className="font-bold text-sm">
                        Name :{" "}
                        <span className="font-normal text-sm">
                          {item.firstName}&nbsp;{item.lastName}
                        </span>
                      </h5>
                    </div>
                    <div className="flex">
                      <h5 className="text-sm font-bold">
                        Contact :{" "}
                        <span className="font-normal text-sm">
                          +{item.dialCode}&nbsp;{item.phone}
                        </span>
                      </h5>
                    </div>
                    <div className="flex">
                      <h5 className="text-sm font-bold">
                        Language :{" "}
                        <span className="font-normal text-sm">
                          {item.language && item.language?.length > 0
                            ? item.language.map((lang) => lang.name).join(", ")
                            : "N/A"}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <input
                    onChange={(e) => toggleChecked(index, e.target.checked)}
                    name="checkbox"
                    className="h-5 w-5"
                    type="checkbox"
                    checked={item.isChecked}
                  />
                </div>
              </div>
            </Card>
          ))}
      </DialogContent>
      <DialogActions
        sx={{
          marginTop: "15px",
        }}
      >
        <Button variant="submit" onClick={handleAssignPandit} type="submit">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssigningDialog.propTypes = {
  setIsAssigned: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
};

export default AssigningDialog;
