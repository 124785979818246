import React from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore, Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "subComponents/Sidenav/SidenavCollapse";
import SidenavRoot from "subComponents/Sidenav/SidenavRoot";
import yajya from "../../assets/images/yajya.svg";
import { logout } from "../../redux/features/signinSlice";

import "./styles/sidenav.css";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const [open, setOpen] = React.useState({});

  const handleClick = (key) => {
    setOpen((prevOpen) => ({ ...prevOpen, [key]: !prevOpen[key] }));
  };

  const toggleSidebar = () => {
    const drawerPaper = document.querySelector(".MuiDrawer-root .MuiDrawer-paper");
    const closeIcon = document.querySelector(".close-icon");
    if (drawerPaper) {
      drawerPaper.classList.toggle("sidebar-visible");
      closeIcon.classList.toggle("close-icon-hidden");
      closeIcon.classList.toggle("close-icon-visible");
    }
  };

  const renderRoutes = (routes) => {
    return routes.map(({ type, name, icon, key, route, collapse }) => {
      if (type === "collapse") {
        return (
          <React.Fragment key={key}>
            <NavLink to={route}>
              {({ isActive }) => (
                <MDBox
                  display="flex"
                  alignItems="center"
                  px={3}
                  py={1}
                  onClick={(e) => {
                    if (collapse) {
                      e.preventDefault();
                      handleClick(key);
                    }
                  }}
                  sx={{
                    backgroundColor: isActive ? "#FD8D4C" : "#ffffff",
                    borderRadius: "8px",
                    boxShadow: isActive ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                    width: "210px",
                    padding: "10px",
                    height: "47px",
                    color: isActive ? "white" : "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Icon sx={{ color: isActive ? "#ffffff" : "#FD8D4C" }}>{icon}</Icon>
                  <MDTypography
                    variant="button"
                    fontWeight="medium"
                    textTransform="capitalize"
                    pl={1}
                    color={isActive ? "white" : "inherit"}
                  >
                    {name}
                  </MDTypography>
                  {collapse ? open[key] ? <ExpandLess /> : <ExpandMore /> : null}
                </MDBox>
              )}
            </NavLink>

            {collapse && (
              <Collapse in={open[key]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {collapse.map((subRoute) => (
                    <NavLink key={subRoute.key} to={subRoute.route}>
                      {({ isActive }) => (
                        <SidenavCollapse
                          name={subRoute.name}
                          icon={subRoute.icon}
                          active={isActive}
                          sx={{
                            backgroundColor: isActive ? "#FD8D4C" : "transparent",
                            boxShadow: isActive ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                            width: "auto",
                            height: "auto",
                            color: isActive ? "white" : "inherit",
                            cursor: "pointer",
                            marginLeft: 2,
                          }}
                        />
                      )}
                    </NavLink>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        );
      } else if (type === "title") {
        return (
          <MDTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
            color="text"
          >
            {name}
          </MDTypography>
        );
      } else if (type === "divider") {
        return <Divider key={key} />;
      }

      return null;
    });
  };

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const handleLogout = () => {
    dispatch(logout());
    navigate("/authentication/sign-in");
  };
  return (
    <>
      {/* Toggle Button */}
      <MDBox
        className="menu-toggle"
        onClick={toggleSidebar}
        sx={{
          position: "fixed",
          top: 16,
          left: 16,
          zIndex: 1200,
          cursor: "pointer",
          mt: 2,
        }}
      >
        <MenuIcon />
      </MDBox>

      {/* Sidebar */}
      <SidenavRoot
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={2}
          pb={1}
          px={3}
        >
          <MDBox alignItems="center">
            {brand && (
              <MDBox
                component="img"
                src={yajya}
                alt="Brand"
                width="5rem"
                onClick={() => navigate("/dashboard")}
              />
            )}
            <MDTypography
              variant="h6"
              textGradient
              sx={{
                background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Hello Admin,
            </MDTypography>
          </MDBox>
          <MDBox
            className="close-icon close-icon-hidden"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              cursor: "pointer",
              position: "absolute",
              right: 16,
              top: "20px",
            }}
            onClick={toggleSidebar}
          >
            <CloseIcon />
          </MDBox>
        </MDBox>
        <Divider />
        <List>{renderRoutes(routes)}</List>
        <MDBox p={2} mt="auto">
          <Button variant="addButton" fullWidth onClick={handleLogout}>
            Log Out
          </Button>
        </MDBox>
      </SidenavRoot>
    </>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
