/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const submit = {
  base: {
    color: "#ffffff",
    backgroundColor: "#FD8D4C",
    // marginTop: "10px",
    "&:hover": {
      backgroundColor: "#fb7038",
    },
  },
};

export default submit;
