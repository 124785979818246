import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Grid, Button } from "@mui/material";
import AssigningDialog from "./AssigningDialog";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LanguageIcon from "@mui/icons-material/Language";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const PendingReqCard = ({ assignData }) => {
  const [isAssigned, setIsAssigned] = useState(false);
  const [orderId, setOrderId] = useState();

  const handleAssignPanditClick = (orderId) => {
    setOrderId(orderId);
    setIsAssigned(true);
  };
  const cardStyle = {
    width: "100%",
    backgroundColor: "#FFF6F0",
    boxShadow: "0 0.25em 0.5em rgba(0, 0, 0, 0.1)",
    borderRadius: "0.75em",
    overflow: "hidden",
    marginBottom: "1.25em",
    padding: "1em", // Converted from 16px
  };

  const labelStyle = {
    fontWeight: "bold",
    marginRight: "0.5em", // Converted from 8px
    fontSize: "0.875em", // Converted from 14px
    color: "#344767",
  };

  const valueStyle = {
    marginBottom: "0.5em",
    fontSize: "0.700em",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: "0.25em",
    color: "#344767",
  };

  const buttonContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box display="flex" flexDirection="column" p={2}>
      {assignData.map((card, index) => {
        const estimReachTime = new Date(card?.estimReachTime);
        const formattedDate = estimReachTime.toLocaleDateString();
        const formattedTime = estimReachTime.toLocaleTimeString();
        const address = card.orderId?.addressId?.address;
        const landmark = card.orderId?.addressId?.landMark;

        return (
          <Card key={index} sx={cardStyle} mb={2}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5}>
                  <Typography variant="body1" style={valueStyle}>
                    <PersonIcon style={iconStyle} />
                    <span style={labelStyle}>UserName:</span>{" "}
                    <span className="text-xs">
                      {card?.orderedUserId?.firstName}
                      &nbsp;
                      {card?.orderedUserId?.lastName}
                    </span>
                  </Typography>
                  <Typography variant="body1" style={valueStyle}>
                    <EventIcon style={iconStyle} />
                    <span style={labelStyle}>Yajya Name:</span>{" "}
                    <span className="text-xs">{card?.planId?.name}</span>
                  </Typography>
                  <Typography variant="body1" style={valueStyle}>
                    <div className="flex">
                      <LocationOnIcon className="mt-1" style={iconStyle} />
                      <span style={labelStyle}>Location:</span>
                      <span className="text-xs mt-1">
                        {card.addressId?.address},&nbsp;
                        {card.addressId?.landMark}
                      </span>
                    </div>
                  </Typography>
                  <Typography variant="body1" style={valueStyle}>
                    <PhoneIcon style={iconStyle} />
                    <span style={labelStyle}>Contact Number:</span>{" "}
                    <span className="text-xs">
                      +{card?.orderedUserId?.dialCode}
                      &nbsp;
                      {card?.orderedUserId?.phone}
                    </span>
                  </Typography>
                  <Typography variant="body1" style={valueStyle}>
                    <CurrencyRupeeIcon style={iconStyle} />
                    <span style={labelStyle}>Amount:</span>{" "}
                    <span className="text-xs">{card.amount}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Typography variant="body1" style={valueStyle}>
                    <EventIcon style={iconStyle} />
                    <span style={labelStyle}>Date:</span>{" "}
                    <span className="text-xs">{formattedDate}</span>
                  </Typography>
                  <Typography variant="body1" style={valueStyle}>
                    <AccessTimeIcon style={iconStyle} />
                    <span style={labelStyle}>Time</span>{" "}
                    <span className="text-xs">{formattedTime}</span>
                  </Typography>
                  {card.planId?.planDuration && (
                    <Typography variant="body1" style={valueStyle}>
                      <AccessTimeIcon style={iconStyle} />
                      <span style={labelStyle}>Duration:</span> {card.planId?.planDuration}&nbsp;hr
                    </Typography>
                  )}

                  <Typography variant="body1" style={valueStyle}>
                    <LanguageIcon style={iconStyle} />
                    <span style={labelStyle}>Language:</span>{" "}
                    <span className="text-xs">{card.orderId?.languagePref?.join(", ")}</span>
                  </Typography>
                </Grid>
                <Grid
                  onClick={() => handleAssignPanditClick(card?.id)}
                  item
                  xs={12}
                  md={2}
                  sx={buttonContainerStyle}
                >
                  <Button variant="addButton">Assign Pandit</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
      {isAssigned && (
        <AssigningDialog orderId={orderId} setIsAssigned={setIsAssigned} open={isAssigned} />
      )}
    </Box>
  );
};

export default PendingReqCard;
