import { useFormContext, Controller } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import TextField from "../shared/input/TextField";

export function RHFSelect({
  name,
  label,
  native,
  defaultValue,
  placeholder,
  maxHeight = 220,
  required = false,
  options,
  helperText,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: `Please Select an Option`,
        },
      }}
      defaultValue={defaultValue || ""}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          {label && (
            <InputLabel id={`${name}-label`} size="small">
              {label}
            </InputLabel>
          )}
          <TextField
            select
            {...field}
            label={label} // Associate the label with the TextField
            bgcolor="transparent"
            InputProps={{
              inputProps: {
                sx: {
                  height: "37px !important",
                },
              },
            }}
            native={native}
            {...other}
          >
            {placeholder && (
              <MenuItem value="">
                <em>{placeholder}</em>
              </MenuItem>
            )}
            {options &&
              options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#FD8D4C",
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
          </TextField>

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
