import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import RHFTextField from "../../../hook-form/RHFTextField";
import RHFImageUpload from "hook-form/RHFImageUpload";

const AddCategoryDialog = ({ open, onClose, editData, title, onSubmit }) => {
  const [image, setImage] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const { register, handleSubmit, setValue } = useFormContext();

  useEffect(() => {
    if (editData) {
      setValue("name", editData?.name || "");
      setValue("description", editData?.description || "");
      setValue("image", editData?.image || "");
      setIsToggled(editData?.isPublished || false);
    }
  }, [editData, setValue]);

  // useEffect(()=>{
  //    setValue("name",)
  // },[])
  // const handleInputChange = (event) => {
  //   const { files } = event.target;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     if (file) {
  //       setImage(URL.createObjectURL(file));
  //     }
  //   }
  // };

  const handleSubmitClick = (data) => {
    const category = {
      name: data.name || "",
      image: data.image || "",
      description: data.description || "",
      isPublished: isToggled || false,
    };
    onSubmit(category);
    onClose();
  };

  const handleToggle = () => setIsToggled(!isToggled);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: "#FD8D4C" }}>{title}</DialogTitle>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <DialogContent>
          <RHFImageUpload
            defaultValue={editData?.image}
            name="image"
            label="Upload Image"
            required
          />
          {/* <div className="">
            <input
              label="Image"
              name="image"
              accept="image/*"
              style={{ display: "none" }}
              id="image-upload"
              type="file"
              onChange={handleInputChange}
            />
            {image ? (
              <div className="mb-4">
                <img src={image} alt="Preview" style={{ width: "100%", height: "50%" }} />
              </div>
            ) : (
              <div className="flex mb-4 justify-center">
                <div className="w-24 h-24 rounded-full border-white bg-opacityColor border">
                  <label className="flex pt-7 justify-center" htmlFor="image-upload">
                    <Icon
                      className="w-9 text-white h-9"
                      icon="x"
                    />
                  </label>
                </div>
              </div>
            )}
          </div> */}
          <RHFTextField name="name" placeholder="Enter name" type="text" fullWidth required />
          <TextField
            margin="dense"
            label="Description"
            name="description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            {...register("description")}
          />
          <MDBox
            className="flex justify-between"
            {...register("publish")}
            display="flex"
            alignItems="center"
          >
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleToggle}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;publish :
            </MDTypography>
            <Switch
              checked={isToggled}
              onChange={handleToggle}
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: isToggled ? "green" : "red",
                },
              }}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button variant="submit" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCategoryDialog;
