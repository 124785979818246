import { apiSlice } from ".";
import URLS from "../constants/api";

export const bookingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // `${URLS.ASSIGNMENT_TRACKING}?sortBy=${sortBy}`
    getBooking: builder.query({
      query: ({ sortBy, search, page }) => {
        let queryString = `${URLS.ASSIGNMENT_TRACKING}?sortBy=${sortBy}`;
        if (search) {
          queryString += `&search=${search}`;
        }
        if (page) {
          queryString += `&page=${page}`;
        }
        return queryString;
      },
      providesTags: ["ASSIGNMENT_TRACKING"],
    }),
    getRelevantPandit: builder.query({
      query: ({ orderId }) => {
        let queryString = `${URLS.RELEVANT_PANDIT}`;
        if (orderId) {
          queryString += `?orderId=${orderId}`;
        }
        return queryString;
      },
      providesTags: ["GETRELEVANTTRACKING"],
    }),
    assignPandit: builder.mutation({
      query: ({ ...body }) => {
        return {
          url: `${URLS.ASSIGNMENT_TRACKING}`,
          method: "POST",
          body,
        };
      },
      providesTags: ["ASSIGNING_PANDIT"],
    }),
  }),
});

export const { useLazyGetBookingQuery, useLazyGetRelevantPanditQuery, useAssignPanditMutation } =
  bookingApiSlice;
