import { apiSlice } from ".";
import URLS from "../constants/api";

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addYagnaCategory: builder.mutation({
      query: (body) => ({
        url: URLS.CATEGORY,
        method: "POST",
        body,
      }),
      providesTags: ["Category"],
    }),
    updateCategory: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${URLS.CATEGORY}?categoryId=${id}`,
        method: "PATCH",
        body: formData,
      }),
      providesTags: ["Category"],
    }),
    getCategory: builder.query({
      query: ({ sortBy, name, page }) => {
        let queryString = `${URLS.CATEGORY}?sortBy=${sortBy}`;
        if (name) {
          queryString += `&name=${name}`;
        }
        if (page) {
          queryString += `&page=${page}`;
        }
        return queryString;
      },
      providedTags: ["Category"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `${URLS.CATEGORY}?categoryId=${id}`,
        method: "DELETE",
      }),
      providesTags: ["Category"],
    }),
  }),
});

export const {
  useAddYagnaCategoryMutation,
  useUpdateCategoryMutation,
  useLazyGetCategoryQuery,
  useGetCategoryQuery,
  useDeleteCategoryMutation,
} = categoryApiSlice;
