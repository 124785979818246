// bannerReducers.js
const addBanner = (state, { payload }) => {
  if (state.banners) {
    state.banners.push(payload);
  } else {
    state.banners = [payload];
  }
};

const getBanner = (state, { payload }) => {
  state.banners = payload;
};

const deleteBanner = (state, { payload }) => {
  state.banners = state.banners.filter((banner) => banner.id !== payload);
  s;
};

export { addBanner, getBanner, deleteBanner };
