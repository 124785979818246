// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addCategory, getCategory } from "../reducers/categoryReducer";

const initialState = {
  category: [],
  error: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    addCategory,
    getCategory,
  },
});

export const { addCategory: addCategoryAction, getCategory: getCategoryAction } =
  categorySlice.actions;
export default categorySlice.reducer;
