import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast, { Toaster } from "react-hot-toast";
import { logoutAction } from "../slices/authSlice";
import URLS from "../constants/api";

// Custom toast styles for smaller notifications
//TODO: create a utilit function for this
export const customToastStyle = {
  success: {
    padding: "8px 16px",
    fontSize: "14px",
    borderRadius: "8px",
  },
  error: {
    padding: "8px 16px",
    fontSize: "14px",
    borderRadius: "8px",
  },
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${URLS.HOST_URL}${URLS.SUFFIX_URL}`,
  credentials: "same-origin",
  prepareHeaders: (headers, { getState }) => {
    const state = getState();
    const deviceId = state.deviceId.deviceId || localStorage.getItem("deviceId");

    const token = localStorage.getItem("accessToken");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    if (deviceId) {
      headers.set("device-id", deviceId);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (arg, api, extraOptions) => {
  const result = await baseQuery(arg, api, extraOptions);

  const method = arg?.method?.toUpperCase() || "GET"; // Default to GET if method is not provided

  if (method !== "GET") {
    if (result?.data?.success) {
      toast.success(result?.data?.message, {
        style: customToastStyle.success,
      });
    }
  }

  if (result?.error?.data) {
    const errorCode = result?.error?.data?.code;
    const errMessage = result?.error?.data?.message;
    if (errorCode === 2018) {
      api.dispatch(logoutAction());
      toast.error(`${errMessage || "Your session has expired. Please login again."} `, {
        style: customToastStyle.error,
      });
    } else {
      toast.error(result?.error?.data?.message, {
        style: customToastStyle.error,
      });
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  tagTypes: ["Auth", "Banner"],
  endpoints: () => ({}),
});

function App() {
  return (
    <>
      <Toaster />
      {/* Your app components */}
    </>
  );
}

export default App;
