import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import CurrentYagnaCard from "layouts/dashboard/components/CurrentYagna/CurrentYagnaCard";

function index({ yagnaData }) {
  const [menu, setMenu] = useState(null);
  const pendingRequestsCount = yagnaData?.length || 0;
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  return (
    <Card
      style={{
        boxShadow: "0px 4px 10px rgba(242, 111, 34, 0.2)",
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Current Yagna
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            {yagnaData && yagnaData?.length > 0 && (
              <>
                <InfoOutlinedIcon
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    mt: -0.5,
                  }}
                />
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;<strong>{pendingRequestsCount} </strong> Yagnas Ongoing
                </MDTypography>
              </>
            )}
          </MDBox>
        </MDBox>
        {/* <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu} */}
      </MDBox>
      <MDBox sx={{ height: "400px", overflow: "auto" }}>
        <CurrentYagnaCard data={yagnaData} />
      </MDBox>
    </Card>
  );
}

export default index;
