import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon } from "@iconify/react";

export default function languageTableData(languageList, handleDeleteClick) {
  const columns = [
    { Header: "Name", accessor: "name", align: "left", width: "100%" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  const rows =
    languageList?.results?.length > 0
      ? languageList.results.map((item, index) => ({
          name: (
            <MDTypography
              key={`name-${index}`}
              style={{
                fontSize: "0.9rem",
              }}
            >
              {item.name}
            </MDTypography>
          ),
          action: (
            <Icon
              className="text-red-800"
              icon="ep:delete"
              onClick={() => handleDeleteClick(item)}
            />
          ),
        }))
      : [
          {
            name: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px", width: "100%" }}
              >
                No Data Found
              </MDTypography>
            ),
            action: "",
          },
        ];

  return {
    columns,
    rows,
  };
}
