import { apiSlice } from ".";
import URLS from "../constants/api";

export const subCategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSubCategory: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${URLS.SUB_CATEGORY}?categoryId=${id}`,
        method: "POST",
        body: formData,
      }),
      providesTags: ["SubCategory"],
    }),
    updateSubCategory: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${URLS.SUB_CATEGORY}?planId=${id}`,
        method: "PATCH",
        body: formData,
      }),
      providesTags: ["SubCategory"],
    }),
    getSubCategory: builder.query({
      // `${URLS.SUB_CATEGORY}?sortBy=${sortBy}`
      query: ({ sortBy, planId, name, planCategory, page }) => {
        let queryString = `${URLS.SUB_CATEGORY}`;
        queryString +=
          name && page
            ? `?sortBy=${sortBy}&name=${name}&page=${page}`
            : name
            ? `?sortBy=${sortBy}&name=${name}`
            : page
            ? `?sortBy=${sortBy}&page=${page}`
            : "";

        if (planId) {
          queryString += `?planId=${planId}`;
        }
        if (planCategory) {
          queryString += `?planCategory=${planCategory}`;
        }
        // if (page) {
        //   queryString += `?sortBy=${sortBy}&page=${page}`;
        // }
        return queryString;
      },
      providedTags: ["SubCategory"],
    }),
    deleteSubCategory: builder.mutation({
      query: (id) => ({
        url: `${URLS.SUB_CATEGORY}?planId=${id}`,
        method: "DELETE",
      }),
      providesTags: ["SubCategory"],
    }),
  }),
});

export const {
  useGetSubCategoryQuery,
  useAddSubCategoryMutation,
  useUpdateSubCategoryMutation,
  useLazyGetSubCategoryQuery,
  useDeleteSubCategoryMutation,
} = subCategoryApiSlice;
