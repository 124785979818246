/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

function WalletTransactionHistory({ walletData }) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleViewAllClick = () => {
    navigate("/wallet-transactions");
  };
  return (
    <Card
      style={{
        boxShadow: "0px 4px 10px rgba(242, 111, 34, 0.2)",
      }}
      sx={{ height: "500px", padding: "20px" }}
    >
      <MDBox className="flex justify-between">
        <MDTypography variant="h6" fontWeight="medium">
          Wallet Transaction History
        </MDTypography>
        <Button
          onClick={handleViewAllClick}
          variant="submit"
          // className="w-20 text-sm text-white h-6 rounded-xl bg-customShadow"
        >
          View All
        </Button>
      </MDBox>
      <div className="mt-4 overflow-y-scroll">
        {walletData?.map((data, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography component="div" sx={{ width: "33%", flexShrink: 0, marginRight: "65px" }}>
                <h1 className="text-xs text-gray-500 w-44">
                  TransactionId <span className="text-black text-xs">{data?.id}</span>
                </h1>
              </Typography>
              <Typography component="div" sx={{}}>
                <h1 className="text-xs text-gray-500 w-20">
                  Status{" "}
                  <span
                    className={` text-xs ${
                      data?.isApproved === true ? "text-green-700" : "text-orange-700"
                    }`}
                  >
                    {data?.isApproved === true ? "approved" : "pending"}
                  </span>
                </h1>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className="flex justify-between">
                <div>
                  <h1 className="text-xs flex text-gray-500 items-center">Pandit Details</h1>
                  <h1 className="text-xs mt-1 text-black  flex items-center">
                    Id : <span className="text-gray-500 ml-1">{data?.panditUserId?.id}</span>
                  </h1>
                  <h1 className="text-xs mt-1 text-black  flex items-center">
                    Name :{" "}
                    <span className="text-gray-500 ml-1">
                      {data?.panditUserId?.firstName} {data?.panditUserId?.lastName}
                    </span>
                  </h1>
                  <h1 className="text-xs mt-1 text-black flex items-center">
                    Contact :{" "}
                    <span className="text-gray-500 ml-1">{data?.panditUserId?.phone}</span>
                  </h1>
                </div>
                <img className="w-10 h-10 mr-3 rounded-full bg-black" src={data?.img} />
              </Typography>
              <hr className="mt-2" />

              <Typography component="div" mt={1}>
                <h1 className="text-sm">
                  PaymentId : <span className="text-gray-500 ml-1">{data?.paymentId || "-"}</span>
                </h1>
              </Typography>
              <Typography component="div">
                <h1 className="text-sm">
                  Ammount : <span className="text-gray-500 ml-1">{data?.amount}</span>
                </h1>
              </Typography>
              <Typography component="div">
                <h1 className="text-sm">
                  Time : <span className="text-gray-500 ml-1">{data?.Time || "-"}</span>
                </h1>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Card>
  );
}

export default WalletTransactionHistory;
