// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

function ComplexStatisticsCard({ color, title, count, icon }) {
  return (
    <Card
      sx={{
        boxShadow: "0px 4px 10px rgba(242, 111, 34, 0.2)", // Custom shadow
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          background: "linear-gradient(90deg, #FD8E4B 0%, #FE7165 100%)", // Gradient background
          borderRadius: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "4rem",
          height: "4rem",
          marginBottom: 2,
        }}
      >
        <Icon fontSize="large" sx={{ color: "white !important" }}>
          {icon}
        </Icon>
      </Box>
      <Box textAlign="center">
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          {title}
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {count}
        </Typography>
      </Box>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
