// AuthGuard.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { logoutAction } from "../redux/slices/authSlice";

const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  // const isAuthenticated = true;
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(logoutAction());
    }
  }, [isAuthenticated, dispatch]);

  return isAuthenticated ? children : <Navigate to="/authentication/sign-in" />;
};

export default AuthGuard;
