import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCategoryAction } from "../redux/slices/categorySlice";
import { useLazyGetCategoryQuery } from "../redux/api/categoryApi";
import useFetchData from "./useFetchData";

const useFetchCategory = () => {
  const categoryData = useSelector((state) => state?.category.category);
  const [getCategory] = useLazyGetCategoryQuery();
  const fetchCategoryData = useFetchData(getCategory, getCategoryAction);
  const planOptions =
    categoryData?.results?.map((item) => ({
      label: item.name,
      value: item.id,
    })) || [];

  useEffect(() => {
    if (!categoryData?.results || categoryData?.results?.length === 0) {
      fetchCategoryData({ sortBy: "createdAt:desc" });
    }
  }, [categoryData, fetchCategoryData]);

  return planOptions;
};

export default useFetchCategory;
