import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "../api/index";

import authReducer from "../slices/authSlice";
import bannerReducer from "../slices/bannerSlice";
import categoryReducer from "../slices/categorySlice";
import subCategoryReducer from "../slices/subCategorySlice";
import panditsReducer from "../slices/panditsSlice";
import walletTransactionsReducer from "../slices/walletTransactionsSlice";
import notificationReducer from "../slices/notificationSlice";
import samagriReducer from "../slices/samagriSlice";
import dashboardReducer from "../slices/dashboardSlice";
import lanuageReducer from "../slices/languageSlice";
import bookingReducer from "../slices/bookingSlice";
import cityReducer from "../slices/citySlice";
import deviceIdReducer from "../slices/deviceTokenLocSlice";
import panditOnboardReducer from "../slices/panditOnboardSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,

    auth: authReducer,
    deviceId: deviceIdReducer,
    banner: bannerReducer,
    category: categoryReducer,
    subCategory: subCategoryReducer,
    pandits: panditsReducer,
    walletTransactions: walletTransactionsReducer,
    notification: notificationReducer,
    samagri: samagriReducer,
    dashboard: dashboardReducer,
    language: lanuageReducer,
    booking: bookingReducer,
    city: cityReducer,
    panditOnboard: panditOnboardReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
export default store;
