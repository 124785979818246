import { useCallback } from "react";
import { useDispatch } from "react-redux";

const useFetchData = (apiCall, action) => {
  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (params) => {
      try {
        const response = await apiCall(params).unwrap();
        if (response) {
          dispatch(action(response?.data));
        }
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    },
    [dispatch, apiCall, action]
  );

  return fetchData;
};

export default useFetchData;
