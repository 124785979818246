import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button } from "@mui/material";
import { Icon } from "@iconify/react";
import toast, { Toaster } from "react-hot-toast";
import { customToastStyle } from "../../../redux/api";
import { useSendNotificationMutation } from "../../../redux/api/notificationApi";

export default function notificationTableData(
  notificationList,
  handleEditClick,
  handleDeleteClick
) {
  const [sendNotification] = useSendNotificationMutation();

  const handleSendNotification = async (item) => {
    try {
      const sendNotificationResponse = await sendNotification(item.id);
      if (!sendNotificationResponse?.data?.success) {
        toast.error(`${sendNotificationResponse?.data?.message || "Something went wrong!"} `, {
          style: customToastStyle.error,
        });
      } else {
        toast.success("Notification sent successfully!", {
          style: customToastStyle.success,
        });
      }
    } catch (err) {
      console.error("Failed to send notification:", err);
      toast.error("Failed to send notification.", { style: customToastStyle.error });
    }
  };

  const Author = ({ title, subTitle, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <h1 className="mr-4 font-bold">{id}.</h1>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography variant="caption">{subTitle}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const columns = [
    { Header: "Message", accessor: "message", width: "40%", align: "left" },
    { Header: "Notification Type", accessor: "notificationType", width: "20%", align: "center" },
    { Header: "User Type", accessor: "typeDropdown", width: "20%", align: "center" },
    { Header: "Device Type", accessor: "deviceType", align: "center", width: "20%" },
    { Header: "Action", accessor: "action", align: "center" },
    { Header: "Send", accessor: "submit", align: "left" },
  ];

  const rows =
    notificationList?.results?.length > 0
      ? notificationList.results.map((item, index) => ({
          message: (
            <Author
              title={item?.data?.title || "-"}
              id={index + 1}
              subTitle={item?.data?.body || "-"}
            />
          ),
          submit: (
            <Button onClick={() => handleSendNotification(item)} variant="submit">
              Send
            </Button>
          ),
          action: (
            <MDTypography className="flex">
              <Icon
                className="text-black mr-2"
                icon="ep:edit"
                onClick={() => handleEditClick(item)}
              />
              <Icon
                className="text-red-800"
                icon="ep:delete"
                onClick={() => handleDeleteClick(item)}
              />
            </MDTypography>
          ),
          notificationType: (
            <MDTypography
              style={{
                fontSize: "0.9rem",
              }}
            >
              {item.type || "-"}
            </MDTypography>
          ),
          typeDropdown: (
            <MDTypography
              style={{
                fontSize: "0.9rem",
              }}
            >
              {item.userType || "-"}
            </MDTypography>
          ),
          deviceType: (
            <MDTypography
              style={{
                fontSize: "0.9rem",
              }}
            >
              {item.deviceType || "-"}
            </MDTypography>
          ),
        }))
      : [
          {
            message: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px", width: "100%" }}
                colSpan={columns.length}
              >
                No Data Found
              </MDTypography>
            ),
            notificationType: "",
            typeDropdown: "",
            deviceType: "",
            action: "",
            submit: "",
          },
        ];

  return {
    columns,
    rows,
  };
}
