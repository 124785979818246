import "./index.css";

const NotificationMessage = ({ notification }) => {
  return (
    <div className="notification">
      {notification.image && (
        <div className="image-container">
          <img src={notification.image} alt="Notification" />
        </div>
      )}
      <div className="text-container">
        <div className="notification-title">{notification.title}</div>
        <div className="notification-body">{notification.body}</div>
      </div>
    </div>
  );
};

export default NotificationMessage;
