import React from "react";

const useFilter = () => {
  const initialFilter = {
    sortBy: "createdAt:desc",
    limit: 12,
  };
  const [filters, setFilters] = React.useState(initialFilter);

  const handleFilterChange = (columnId, value) => {
    setFilters({ ...filters, [columnId]: value });
  };

  return { filters, handleFilterChange, setFilters };
};

export default useFilter;
