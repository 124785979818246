import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Grid, Button } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import TransactionDialog from "./TransactionDialogue";

// const validateCardData = (card) => {
//   const requiredFields = [
//     "userName",
//     "yajyaName",
//     "location",
//     "contactNumber",
//     "timestamp",
//     "date",
//     "timeSlot",
//     "preferredLanguage",
//     "amount",
//   ];

//   return requiredFields.every((field) => card.hasOwnProperty(field) && card[field]);
// };

const WalletCard = ({ walletData }) => {
  const [isApprove, setIsApprove] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const handleApproveClick = (card) => {
    setTransactionId(card.id);
    setIsApprove(true);
  };
  const cardStyle = {
    width: "100%",
    backgroundColor: "#FFF6F0",
    boxShadow: "0 0.25em 0.5em rgba(0, 0, 0, 0.1)", // Converted from 0 4px 8px
    borderRadius: "0.75em", // Converted from 12px
    overflow: "hidden",
    marginBottom: "1.25em", // Converted from 20px
    padding: "1em", // Converted from 16px
  };

  const labelStyle = {
    fontWeight: "bold",
    marginRight: "0.5em", // Converted from 8px
    fontSize: "0.875em", // Converted from 14px
    color: "#344767",
  };

  const valueStyle = {
    marginBottom: "0.5em", // Converted from 8px
    fontSize: "0.775em", // Converted from 14px
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: "0.25em", // Converted from 4px
    color: "#344767",
  };

  const timestampStyle = {
    fontSize: "0.75em", // Converted from 12px
  };

  const buttonContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box display="flex" flexDirection="column" p={2}>
      {walletData?.map((card, index) => (
        <Card key={index} sx={cardStyle} spacing={2} mb={2}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={6} md={5}>
                <Typography variant="body1" style={valueStyle}>
                  {/* <PersonIcon style={iconStyle} /> */}
                  {/* {card?.panditUserId?.id} */}
                  <span style={labelStyle}>PanditUserId:</span>{" "}
                  <span className="text-xs">{card?.panditUserId?.id}</span>
                </Typography>
                <Typography variant="body1" style={valueStyle}>
                  {/* <PersonIcon style={iconStyle} /> */}
                  <span style={labelStyle}>PanditName:</span>{" "}
                  <span className="text-xs">{card?.panditUserId?.firstName} </span>
                  {/* {card?.panditUserId?.lastName} */}
                </Typography>
                <Typography variant="body1" style={valueStyle}>
                  {/* <PersonIcon style={iconStyle} /> */}
                  <span style={labelStyle}>PanditContact:</span>{" "}
                  <span className="text-xs">{card?.panditUserId?.phone}</span>
                </Typography>
                {/* <Typography variant="body2" color="textSecondary" style={timestampStyle}>
                    Added {formatDistanceToNow(card.timestamp)} ago
                  </Typography> */}
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Typography variant="body1" style={valueStyle}>
                  {/* <EventIcon style={iconStyle} /> */}
                  <span style={labelStyle}>Amount:</span>{" "}
                  <span className="text-xs">{card?.amount}</span>
                </Typography>
                <Typography variant="body1" style={valueStyle}>
                  <span style={labelStyle}>IsApproved:</span>{" "}
                  <span
                    className={`text-xs ${card.isApproved ? "text-green-700" : "text-red-700"}`}
                  >
                    {card.isApproved ? "Approved" : "Pending"}
                  </span>
                </Typography>
                <Typography variant="body1" style={valueStyle}>
                  <span style={labelStyle}>ApprovedBy:</span> {card.isApproved}
                </Typography>
              </Grid>
              <Grid
                onClick={() => handleApproveClick(card)}
                item
                xs={12}
                md={2}
                sx={buttonContainerStyle}
              >
                <Button variant="addButton">Approve</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        // ) : (
        //   <Typography key={index} color="error">
        //     Invalid data for card {index + 1}
        //   </Typography>
      ))}
      {isApprove ? (
        <TransactionDialog transactionId={transactionId} setIsApprove={setIsApprove} />
      ) : (
        ""
      )}
    </Box>
  );
};

export default WalletCard;
