// subCategoryReducers.js
const addCity = (state, { payload }) => {
  if (state.city) {
    state.city.push(payload);
  } else {
    state.city = [payload];
  }
};

const getCity = (state, { payload }) => {
  state.city = payload;
};

export { addCity, getCity };
