import { apiSlice } from ".";
import URLS from "../constants/api";

export const panditOnboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addPanditOnboard: builder.mutation({
      query: (body) => ({
        url: `${URLS.PANDIT_ONBOARD}`,
        method: "POST",
        body,
      }),
      providesTags: ["PanditOnboard"],
    }),
  }),
});

export const { useAddPanditOnboardMutation } = panditOnboardApiSlice;
