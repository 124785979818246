// categoryReducers.js
const addCategory = (state, { payload }) => {
  if (state.category) {
    state.category.push(payload);
  } else {
    state.category = [payload];
  }
};

const getCategory = (state, { payload }) => {
  state.category = payload;
};

export { addCategory, getCategory };
