// notificationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addNotification, getNotification } from "../reducers/notificationReducer";

const initialState = {
  notification: [],
  error: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification,
    getNotification,
  },
});

export const { addNotification: addNotificationAction, getNotification: getNotificationAction } =
  notificationSlice.actions;
export default notificationSlice.reducer;
