// samagriReducers.js
const addSamagri = (state, { payload }) => {
  if (state.samagri) {
    state.samagri.push(payload);
  } else {
    state.samagri = [payload];
  }
};

const getSamagri = (state, { payload }) => {
  state.samagri = payload;
};

export { addSamagri, getSamagri };
