import { apiSlice } from ".";
import URLS from "../constants/api";

export const walletTransactionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWalletTransaction: builder.query({
      query: ({ sortBy, search, page }) => {
        let queryString = `${URLS.WALLETTRANSACTION}?sortBy=${sortBy}`;
        if (search) {
          queryString += `&search=${search}`;
        }
        if (page) {
          queryString += `&page=${page}`;
        }
        return queryString;
      },
      providesTags: ["WALLETTRANSACTION"],
    }),
    addWalletPayout: builder.mutation({
      query: (body) => ({
        url: `${URLS.WALLET_PAYOUT}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["WALLETTRANSACTION"],
    }),
  }),
});

export const { useLazyGetWalletTransactionQuery, useAddWalletPayoutMutation } =
  walletTransactionApiSlice;
