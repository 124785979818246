// subCategoryReducers.js
const addLanguage = (state, { payload }) => {
  if (state.notification) {
    state.language.push(payload);
  } else {
    state.notification = [payload];
  }
};

const getLanguage = (state, { payload }) => {
  state.language = payload;
};

export { addLanguage, getLanguage };
