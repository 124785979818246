import { createSlice } from "@reduxjs/toolkit";
import { setDeviceId } from "../reducers/deviceTokenLocReducer";

const initialState = {
  deviceId: "",
  deviceLocName: "",
  error: null,
};
const deviceIdSlice = createSlice({
  name: "deviceId",
  initialState,
  reducers: {
    setDeviceId,
  },
});

export const { setDeviceId: setDeviceIdAction } = deviceIdSlice.actions;
export default deviceIdSlice.reducer;
