export default {
  primary: "#F49B36",
  secondary__fill: "#0B1739",
  secondary__fill__dark: "#0A1330",
  purple: "#635BFF",
  purple__opacity: "rgba(91, 99, 255, 0.20)",
  yellow: "#F8C20A",
  yellow__opacity: "rgba(248, 194, 10, 0.20)",
  tealGreen: "#16CDC7",
  tealGreen__opacity: "rgba(22, 205, 199, 0.20)",
  green: "#00F586",
  green__opacity: "rgba(0, 245, 134, 0.20)",
  active: "#36C7C3",
  active__opacity: "rgba(54, 199, 195, 0.20)",
  success: "#36C76C",
  success__opacity: "rgba(54, 199, 108, 0.20)",
  delete: "#FF6692",
  delete__opacity: "rgba(255, 102, 146, 0.20)",
  notification: "#E62E7B",
  danger: "#EB001B",
  danger__opacity: "rgba(235, 0, 27, 0.20)",
  primary__lighter: "#3174D8",
  primary__dark: "#18b263",
  secondary: "#223D65",
  secondary__light: "rgba(0, 0, 0, 0.15)",
  tertiary: "#00A95B",
  quaternary: "#ED4713",
  default: "#999999",
  grey__light: "#666666",
  grey__dark: "#19191a",
  white: "#fff",
  black: "#000",
  orange__dark: "#dd4409",
  orange__lightsalmon: "#fff3e8",
  blue__gray: "#343B4F",
  light: "#f1f2fc",
  top__bar: "#2c2c2c",
  left__sidebar: "#2c2c2c",
  linkColor: "#4d4de2",
  linkFontSize: "12px",
  replyWindowColor: "#dcf0e6",
  dividerColor: "#e5e9f0",
  btnText__dark: "#1C2B36",
  border_color: "#343B4F",
  placeholder__text: "rgba(255, 255, 255, 0.20)",
  deep_saffron: "rgba(244, 155, 54, 1)",
  thumbnain_title__text: "#BDBDBD",
};
