import { apiSlice } from ".";
import URLS from "../constants/api";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginInUser: builder.mutation({
      query: (body) => ({
        url: URLS.LOGIN,
        method: "POST",
        body,
      }),
      providesTags: ["loginUser"],
    }),
    // signinUser: builder.query({
    //   query: () => `/auth/admin/login1`,
    //   providesTags: ["getUser"],
    // }),
    // changePassword: builder.mutation({
    //   query: (body) => ({
    //     url: "auth/chnagepassword",
    //     method: "PUT",
    //     body,
    //   }),
    //   providesTags: ["getUser"],
    // }),
  }),
});

export const { useLoginInUserMutation } = authApiSlice;
