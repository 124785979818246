// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getPandits } from "../reducers/panditsReducer";

const initialState = {
  pandits: [],
  error: null,
};

const panditsSlice = createSlice({
  name: "pandits",
  initialState,
  reducers: {
    getPandits,
  },
});

export const { getPandits: getPanditsAction } = panditsSlice.actions;
export default panditsSlice.reducer;
