import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PanditProfile from "../../../assets/images/panditProfile.jpeg";

export default function authorsTableData({ walletTransactionList }) {
  const Author = ({ image, name, contact, lastname }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image || PanditProfile} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name || "-"} {lastname || "-"}
        </MDTypography>
        <MDTypography variant="caption">{contact}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const columns = [
    { Header: "Pandit", accessor: "name", align: "left" },
    { Header: "Pandit User ID", accessor: "panditUserId", align: "left" },
    { Header: "Amount", accessor: "amount", align: "center" },
    { Header: "Is Approved", accessor: "isApproved", align: "center" },
    { Header: "Approved By", accessor: "approvedBy", align: "center" },
  ];

  const rows =
    walletTransactionList?.results?.length > 0
      ? walletTransactionList.results.map((item) => ({
          name: (
            <Author
              image={item?.panditUser?.profileImage}
              name={item?.panditUser?.firstName || "-"}
              lastname={item?.panditUser?.lastName || "-"}
              contact={item.panditUser?.phone || "-"}
            />
          ),
          panditUserId: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.panditUser?._id || "-"}
            </MDTypography>
          ),
          amount: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.amount || "-"}
            </MDTypography>
          ),
          isApproved: (
            <MDTypography
              style={{
                color: item.isApproved ? "#047857" : "#ea580c",
                fontSize: "0.8rem",
              }}
            >
              {item.isApproved ? "approved" : "pending"}
            </MDTypography>
          ),
          approvedBy: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.approvedBy?.name || "-"}
            </MDTypography>
          ),
        }))
      : [
          {
            name: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px", width: "100%" }}
                colSpan={columns.length}
              >
                No Data Found
              </MDTypography>
            ),
          },
        ];

  return {
    columns,
    rows,
  };
}
