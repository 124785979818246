// authActions.js
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const signinUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `http://localhost:3000/v1/auth/admin/login`,
        { email, password },
        config
      );
      // Return data to be used as the payload in the fulfilled action
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
