import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import toast, { Toaster } from "react-hot-toast";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import { useSelector } from "react-redux";
import { useMaterialUIController } from "context";

import LayoutWrapper from "./LayoutWrapper";
import routes from "routes";
import AuthGuard from "./Guards/AuthGuards";
import { messaging } from "./config/firebaseConfig";
import { onMessage } from "firebase/messaging";
import useRequestPermission from "./config/requestFCMPermission";
import NotificationMessage from "./components/Notification";
import { connectSocket, disconnectSocket } from "./config/socketIo";

export default function App() {
  const [controller] = useMaterialUIController();
  const { direction, darkMode } = controller;
  const { pathname } = useLocation();
  const userToken = useSelector((state) => state?.auth?.userToken);
  const deviceId = useSelector((state) => state?.deviceId?.deviceId);

  useRequestPermission();

  //TODO: uncomment once Setup is remaining for production
  // useEffect(() => {
  //   if (userToken && deviceId) {
  //     connectSocket(userToken, deviceId);

  //     return () => {
  //       disconnectSocket();
  //     };
  //   }
  // }, [userToken, deviceId]);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      toast(<NotificationMessage notification={payload.notification} />);
    });
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.auth ? (
                <AuthGuard>
                  <LayoutWrapper>
                    <route.component />
                  </LayoutWrapper>
                </AuthGuard>
              ) : (
                <route.component />
              )
            }
            key={route.route || route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider
      theme={
        direction === "rtl" ? (darkMode ? themeDarkRTL : themeRTL) : darkMode ? themeDark : theme
      }
    >
      <Toaster position="top-right" reverseOrder={false} />
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
