import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button, Switch } from "@mui/material";
import { Icon } from "@iconify/react";
import { RHFSelect } from "../../../hook-form/RHFSelect";

export default function cityTableData(cityList, handleDeleteClick, handleIsServicableCityId) {
  const columns = [
    { Header: "State", accessor: "state", align: "left", width: "25%" },
    { Header: "City", accessor: "city", align: "left", width: "25%" },
    { Header: "isServicable", accessor: "isServicable", align: "center", width: "25%" },
    { Header: "Action", accessor: "action", align: "center", width: "25%" },
  ];

  const rows =
    cityList?.results?.length > 0
      ? cityList.results.map((item, index) => ({
          state: (
            <MDTypography
              style={{
                fontSize: "0.9rem",
              }}
            >
              {item?.state || "-"}
            </MDTypography>
          ),
          city: (
            <MDTypography
              style={{
                fontSize: "0.9rem",
              }}
            >
              {item?.name || "-"}
            </MDTypography>
          ),
          isServicable: (
            <Switch
              checked={item.isServicable}
              onChange={() => handleIsServicableCityId(item)}
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: item.isServicable ? "green" : "red",
                },
              }}
            />
          ),
          action: (
            <Icon
              className="text-red-800 cursor-pointer"
              icon="ep:delete"
              onClick={() => handleDeleteClick(item)}
            />
          ),
        }))
      : [
          {
            state: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px", width: "100%" }}
              >
                No Data Found
              </MDTypography>
            ),
            city: "",
            isServicable: "",
            action: "",
          },
        ];

  return {
    columns,
    rows,
  };
}
