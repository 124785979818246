// subCategoryReducers.js
const addNotification = (state, { payload }) => {
  if (state.notification) {
    state.notification.push(payload);
  } else {
    state.notification = [payload];
  }
};

const getNotification = (state, { payload }) => {
  state.notification = payload;
};

export { addNotification, getNotification };
