import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "subComponents/Tables/DataTable";
import { useForm, FormProvider } from "react-hook-form";
import { Button, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import toast from "react-hot-toast";
import ConfirmDelete from "../../shared/deleteDialog";
import { RHFSelect } from "../../hook-form/RHFSelect";
import {
  useLazyGetCityQuery,
  useDeleteCityMutation,
  useAddCityMutation,
  useLazyGetCityStateQuery,
  useIsServicableCityMutation,
} from "../../redux/api/cityApi";
import { useDebounce } from "@uidotdev/usehooks";
import useFilter from "utility/useFilter";
import { customToastStyle } from "../../redux/api";
import cityTableData from "./data/cityTableData";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function City() {
  const methods = useForm();
  const [cityList, setCityList] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [page, setPage] = useState(1);
  const [isServicableCityId, setIsServicableCityId] = useState();
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [getCity] = useLazyGetCityQuery();
  const [addCity, { isLoading: isAddCityLoading }] = useAddCityMutation();
  const [deleteCity, { isLoading: isDeleteCityLoading }] = useDeleteCityMutation();
  const [isServicableCity, { isLoading: isServicableCityLoading }] = useIsServicableCityMutation();
  const [getCityState] = useLazyGetCityStateQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const { filters, setFilters } = useFilter();

  const selectedState = methods.watch("state");

  const confirmDeleteHandler = () => {
    if (deleteIndex) {
      handleDeleteCity(deleteIndex);
    }
    setDeleteIndex(null);
  };

  const handleCloseDelete = () => {
    setDeleteIndex(null);
  };

  const handleSubmitClick = async (data) => {
    try {
      const cityResponse = await addCity(data);
      if (!cityResponse?.data?.success) {
        toast.error("Something went wrong!", {
          style: customToastStyle.error,
        });
      } else {
        methods.reset();
      }
    } catch (err) {
      console.error("Failed to save city:", err);
    }
  };

  const handleDeleteCity = async (item) => {
    try {
      await deleteCity(item.id).unwrap();
    } catch (err) {
      console.error("Failed to delete city:", err);
    }
  };

  const handleIsServicableCityId = async (city) => {
    try {
      await isServicableCity(city?.id);
    } catch (error) {
      console.error("Failed to fetch city data:", error);
    }
  };
  const handleChange = (e, p) => {
    setPage(p);
  };
  useEffect(() => {
    const fetchCityStateData = async () => {
      try {
        const cityStateData = await getCityState();
        if (cityStateData?.data?.data?.length > 0) {
          const fetchedStates = cityStateData?.data?.data[0]?.states?.map((state) => ({
            ...state,
            label: state.name,
            value: state.name,
          }));
          setStates(fetchedStates);
        }
      } catch (error) {
        console.error("Failed to fetch state data:", error);
      }
    };
    fetchCityStateData();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const selectedStateData = states?.find((state) => state.value === selectedState);
      const filteredCities =
        selectedStateData?.cities?.map((city) => ({
          label: city.name,
          value: city.name,
        })) || [];
      setCities(filteredCities);
    }
  }, [selectedState]);

  useEffect(() => {
    const fetchCityData = async () => {
      try {
        const cityData = await getCity(filters);
        if (cityData?.data?.success) setCityList(cityData?.data?.data);
      } catch (error) {
        console.error("Failed to fetch city data:", error);
      }
    };
    fetchCityData();
  }, [filters, isDeleteCityLoading, isAddCityLoading, isServicableCityLoading]);

  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, search: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, setFilters, page]);

  const { columns, rows } = cityTableData(
    cityList,
    (item) => setDeleteIndex(item),
    (item) => handleIsServicableCityId(item)
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitClick)}>
        <div className="flex justify-end">
          <div className="flex mr-2 w-2/6 space-x-2">
            <div className="w-full -mt-8">
              <label className="text-xs font-bold">State</label>
              <RHFSelect name="state" options={states} required />
            </div>
            <div className="w-full -mt-8">
              <label className="text-xs font-bold">City</label>
              <RHFSelect name="name" options={cities} required />
            </div>
          </div>
          <Button type="submit" variant="addButton">
            Add City
          </Button>
        </div>
        <MDBox pt={5} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  style={{
                    backgroundColor: "#FD8D4C",
                    color: "#FFFFFF",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" sx={{ color: "black" }}>
                    City
                  </MDTypography>
                  <input
                    className="text-sm p-2 ml-2 text-gray-700 rounded-md w-56"
                    type="text"
                    placeholder="Search By State/City"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {deleteIndex !== null && (
          <ConfirmDelete
            title=""
            fullMessage={"Are you sure you want to Delete this City?"}
            handleClose={handleCloseDelete}
            deleteHandler={confirmDeleteHandler}
            open={deleteIndex !== null}
          />
        )}
      </form>
      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={cityList.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </FormProvider>
  );
}

export default City;
