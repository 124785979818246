import { apiSlice } from ".";
import URLS from "../constants/api";

export const cityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCityState: builder.query({
      query: () => `${URLS.CITY_STATE}`,
      providesTags: ["CITY_STATE"],
    }),
    getCity: builder.query({
      query: ({ sortBy, search, page }) => {
        let queryString = `${URLS.CITY}?sortBy=${sortBy}`;
        if (search) {
          queryString += `&search=${search}`;
        }
        if (page) {
          queryString += `&page=${page}`;
        }
        return queryString;
      },
      providesTags: ["City"],
    }),
    addCity: builder.mutation({
      query: ({ ...body }) => ({
        url: `${URLS.CITY}`,
        method: "POST",
        body,
      }),
      providesTags: ["City"],
    }),
    deleteCity: builder.mutation({
      query: (id) => ({
        url: `${URLS.CITY}?cityId=${id}`,
        method: "DELETE",
      }),
      providesTags: ["City"],
    }),
    isServicableCity: builder.mutation({
      query: (id) => ({
        url: `${URLS.CITY}?cityId=${id}`,
        method: "PATCH",
      }),
      providesTags: ["City"],
    }),
  }),
});

export const {
  useLazyGetCityQuery,
  useLazyGetCityStateQuery,
  useDeleteCityMutation,
  useAddCityMutation,
  useIsServicableCityMutation,
} = cityApiSlice;
