import { apiSlice } from ".";
import URLS from "../constants/api";

export const languageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLanguage: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${URLS.LANGUAGE}`,
        method: "POST",
        body,
      }),
      providesTags: ["Language"],
    }),
    getLanguage: builder.query({
      query: ({ sortBy, name, page }) => {
        let queryString = `${URLS.LANGUAGE}?sortBy=${sortBy}`;
        if (name) {
          queryString += `&name=${name}`;
        }
        if (page) {
          queryString += `&page=${page}`;
        }
        return queryString;
      },
      providedTags: ["Language"],
    }),
    deleteLanguage: builder.mutation({
      query: (id) => ({
        url: `${URLS.LANGUAGE}?languageId=${id}`,
        method: "DELETE",
      }),
      providesTags: ["Language"],
    }),
  }),
});

export const {
  useLazyGetLanguageQuery,
  useGetLanguageQuery,
  useDeleteLanguageMutation,
  useAddLanguageMutation,
} = languageApiSlice;
