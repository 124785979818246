import React, { useState } from "react";
import RHFTextField from "../../../hook-form/RHFTextField";
import { useForm, FormProvider, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Button, Grid, Checkbox, FormControlLabel } from "@mui/material";
import BG from "../../../assets/images/logos/Back1.jpg";
import { RHFSelect } from "../../../hook-form/RHFSelect";
import { RHFMultiSelect } from "hook-form/RHFMultiSelect";
import RHFFormImageUpload from "../../../hook-form/RHFFormImageUpload";
import useFetchSubCategory from "utility/useFetchSubCategory";
import { useGetLanguageQuery } from "../../../redux/api/languageApi";
import { useAddPanditOnboardMutation } from "../../../redux/api/panditOnboardApi";
import { useGetSubCategoryQuery } from "../../../redux/api/subCategoryApi";
import toast, { Toaster } from "react-hot-toast";
import useFilter from "utility/useFilter";
import { customToastStyle } from "../../../redux/api";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const navigate = useNavigate();
  const [selectedDocuments, setSelectedDocuments] = useState({});
  const { filters } = useFilter();
  const { data: languageData } = useGetLanguageQuery(filters);
  const [addPanditOnboard] = useAddPanditOnboardMutation();
  // const subCategoryOptions = useFetchSubCategory();
  const { data: subCategoryData } = useGetSubCategoryQuery(filters);
  // const planOptions = useFetchCategory();
  const subCategoryOptions = subCategoryData?.data?.results?.map((subCategory) => ({
    label: subCategory.name,
    value: subCategory.id,
  }));
  const methods = useForm({});
  const languageOptions = languageData?.data?.results?.map((language) => ({
    label: language.name,
    value: language.id,
  }));
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    setSelectedDocuments((prev) => ({ ...prev, [type]: file }));
  };
  const handleSubmitClick = async (data) => {
    const {
      aadharCard,
      panCard,
      drivingLicense,
      profileImage,
      coverPhoto,
      address,
      language,
      offeredServices,
      ...filteredData
    } = data;

    const formData = new FormData();

    // Append other non-file data
    Object.entries(filteredData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (address) formData.append("address[0].address", address);

    // Combine the array handling
    const arrayFields = { language, offeredServices };

    Object.entries(arrayFields).forEach(([key, array]) => {
      if (array && Array.isArray(array)) {
        array.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      }
    });

    // Append files with the correct structure for documentVerification
    if (selectedDocuments?.aadharCard)
      formData.append("documentVerification[aadharCard]", selectedDocuments?.aadharCard);
    if (selectedDocuments?.panCard)
      formData.append("documentVerification[panCard]", selectedDocuments?.panCard);
    if (selectedDocuments?.drivingLicense)
      formData.append("documentVerification[drivingLicense]", selectedDocuments?.drivingLicense);
    if (profileImage) formData.append("profileImage", profileImage);
    if (coverPhoto) formData.append("coverPhoto", coverPhoto);

    try {
      const response = await addPanditOnboard(formData);

      if (response?.data?.success) {
        methods.reset();
        navigate("/pandits");
      } else {
        toast.error(`${response?.data?.message || "Something went wrong!"} `, {
          style: customToastStyle.error,
        });
      }
    } catch (err) {
      console.error("Failed to add pandit:", err);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitClick)}>
        <Card>
          <Box
            sx={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: `url(${BG})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              padding: "10px",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <h2 className="ml-4 text-sm">Profile Image</h2>
                <RHFFormImageUpload name="profileImage" label="Profile Image" />
              </Grid>
              <Grid item xs={12} md={6}>
                <h2 className="ml-4 text-sm">Cover Image</h2>
                <RHFFormImageUpload name="coverPhoto" label="Cover Photo" />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">First Name</label>
                <RHFTextField
                  name="firstName"
                  placeholder="Enter Your First Name"
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Last Name</label>
                <RHFTextField
                  name="lastName"
                  placeholder="Enter Your Last Name"
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Dial Code</label>
                <RHFTextField
                  name="dialCode"
                  placeholder="Enter Dial Code"
                  type="number"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Phone Number</label>
                <RHFTextField
                  name="phone"
                  placeholder="Enter Your Number"
                  type="number"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Email</label>
                <RHFTextField
                  name="email"
                  placeholder="Enter Email"
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Address</label>
                <RHFTextField
                  name="address"
                  placeholder="Enter Your Address"
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Select Language</label>
                <RHFMultiSelect
                  name="language"
                  placeholder="Choose language"
                  options={languageOptions}
                  required
                />
                {/* <RHFSelect name="language" options={languageOptions} required /> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Amount</label>
                <RHFTextField
                  name="amount"
                  placeholder="Enter Amount"
                  type="number"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Offered Services</label>
                <RHFMultiSelect
                  name="offeredServices"
                  placeholder="Choose services"
                  options={subCategoryOptions}
                  required
                />
                {/* <RHFSelect name="offeredServices" options={subCategoryOptions} required /> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Wallet Password</label>
                <RHFTextField
                  name="walletPassword"
                  placeholder="Enter 4-Digit Password"
                  type="number"
                  fullWidth
                  required
                  inputProps={{ maxLength: 4 }}
                  onInput={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Aadhar Card</label>
                <Box display="flex" alignItems="center">
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={(e) => handleFileChange(e, "aadharCard")}
                    style={{ marginRight: "16px" }}
                  />
                  <RHFTextField
                    name="aadharCard"
                    placeholder="Aadhar Card"
                    type="text"
                    fullWidth
                    value="Aadhar Card"
                    readOnly
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Pan Card</label>
                <Box display="flex" alignItems="center">
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={(e) => handleFileChange(e, "panCard")}
                    style={{ marginRight: "16px" }}
                  />
                  <RHFTextField name="panCard" value="Pan Card" type="text" fullWidth readOnly />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Driving License</label>
                <Box display="flex" alignItems="center">
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={(e) => handleFileChange(e, "drivingLicense")}
                    style={{ marginRight: "16px" }}
                  />
                  <RHFTextField
                    name="drivingLicense"
                    value="Driving License"
                    type="text"
                    fullWidth
                    readOnly
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="black text-sm">Payment Collected</label>
                <FormControlLabel
                  control={
                    <Controller
                      name="paymentCollected"
                      control={methods.control}
                      defaultValue={false}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              border: "1px solid black",
                            },
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                      )}
                    />
                  }
                  label="Cash"
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="submit" type="submit">
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </form>
    </FormProvider>
  );
};
export default Index;
