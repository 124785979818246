// authReducers.js
const setAuthState = (state, { payload }) => {
  state.userInfo = payload.user;
  state.userToken = payload.token;
  state.isAuthenticated = !!payload.token;
  localStorage.setItem("accessToken", payload.token);
};

const logout = (state) => {
  state.userInfo = null;
  state.userToken = null;
  state.isAuthenticated = false;
  localStorage.removeItem("accessToken");
};

const setAuthError = (state, { payload }) => {
  state.error = payload;
};

export { setAuthState, logout, setAuthError };
