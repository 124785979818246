// notificationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addCity, getCity } from "../reducers/cityReducer";

const initialState = {
  city: [],
  error: null,
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    addCity,
    getCity,
  },
});

export const { addCity: addCityAction, getCity: getCityAction } = citySlice.actions;
export default citySlice.reducer;
