/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const addButton = {
  base: {
    color: "#ffffff", // Retains the original color
    backgroundColor: "#FD8D4C", // Retains the original background color
    background: "linear-gradient(90deg, #FD8E4B 0%, #FE7165 100%)", // New gradient background
    height: "40px", // Retains the original height
    // width: "5.625em", // From buttonStyle
    textTransform: "none", // From buttonStyle
    borderRadius: "0.5em", // Converted from 8px
    "&:hover": {
      backgroundColor: "#fb7038", // Retains the hover background color
    },
  },
};

export default addButton;
