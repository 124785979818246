import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import TextFieldStyled from "../shared/input/TextField"; // Adjust the import based on your file structure

function RHFTextField({
  name,
  helperText,
  placeholder,
  sx,
  required = false,
  defaultValue,
  pattern,
  disabled = false,
  bgcolor, // Add bgcolor prop
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: `This field is required`,
        },
        pattern: pattern && {
          value: pattern,
          message: `Please enter a valid value`,
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <TextFieldStyled
          {...field}
          fullWidth
          value={field.value || ""}
          defaultValue={defaultValue}
          error={!!error}
          helperText={error ? error.message : helperText}
          placeholder={placeholder}
          disabled={disabled}
          bgcolor="transparent"
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black",
            },
            ...sx,
          }}
          {...other}
        />
      )}
    />
  );
}

export default RHFTextField;
