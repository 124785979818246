import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Icon } from "@iconify/react";

function RHFFormImageUpload({ name, label, required = false, defaultValue, ...other }) {
  const { control } = useFormContext();
  const [image, setImage] = useState(defaultValue);

  const handleFileChange = (event, onChange) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      setImage(URL.createObjectURL(file));
      onChange(file);
    }
  };

  const handleRemoveFile = (onChange) => {
    setImage(null);
    onChange(null);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: `This field is required`,
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="h-48 z-20 p-4 rounded-lg">
          <input
            name={name}
            accept="image/*"
            style={{ display: "none" }}
            id={`image-upload-${name}`}
            type="file"
            onChange={(event) => handleFileChange(event, onChange)}
            // {...other}
          />
          {image ? (
            <div className="mb-4 justify-between flex">
              <img src={image} alt="Preview" className="w-72 h-40" />
              <div className="flex ml-2 items-center">
                <label className="flex items-center h-10 px-4 py-2 text-xs text-white rounded cursor-pointer bg-customShadow transition-colors duration-200 mr-2">
                  <Icon
                    className="w-5 text-white h-9"
                    icon="material-symbols-light:upload-file-outline-sharp"
                  />
                  Choose File
                  <input
                    type="file"
                    hidden
                    onChange={(event) => handleFileChange(event, onChange)}
                  />
                </label>
                <button
                  onClick={() => handleRemoveFile(onChange)}
                  className="bg-customShadow text-xs h-10 w-20 rounded"
                >
                  <span className="text-white">Delete</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-between">
              <div className="flex border-dashed border-gray-800 border rounded-xl mr-2 w-96 h-40 mb-4 justify-center items-center">
                <label className="flex items-center" htmlFor="image-upload">
                  <Icon
                    className="w-9 text-black h-9"
                    icon="material-symbols-light:upload-file-outline-sharp"
                  />
                </label>
              </div>
              <div className="flex items-center">
                <label className="flex items-center h-10 px-4 py-2 text-xs text-white rounded cursor-pointer bg-customShadow transition-colors duration-200">
                  <Icon
                    className="w-4 text-white h-9"
                    icon="material-symbols-light:upload-file-outline-sharp"
                  />
                  Choose File
                  <input
                    type="file"
                    hidden
                    onChange={(event) => handleFileChange(event, onChange)}
                  />
                </label>
              </div>
            </div>
          )}
          {error && <span className="text-red-500 text-sm">{error.message}</span>}
        </div>
      )}
    />
  );
}

export default RHFFormImageUpload;
