import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

const sidebarWidth = 250;

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } = ownerState;

  const backgroundValue = darkMode ? palette.background.sidenav : palette.white.main;
  const miniSidebarWidth = 80;

  return {
    "& .MuiDrawer-paper": {
      boxShadow: boxShadows.xxl,
      border: "none",
      backgroundColor: transparentSidenav ? "transparent" : backgroundValue,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      width: miniSidenav ? miniSidebarWidth : sidebarWidth,
      overflowX: "hidden",

      [theme.breakpoints.up("md")]: {
        width: sidebarWidth,
      },

      [theme.breakpoints.down("md")]: {
        width: miniSidebarWidth,
      },

      [theme.breakpoints.down("xs")]: {
        width: 0,
      },
    },
  };
});
