import * as React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Icon } from "@iconify/react";

const Category = ({ image, name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image || "-"} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  </MDBox>
);

const CategoryDesc = ({ description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {description}
    </MDTypography>
  </MDBox>
);

export default function getTableData(categoryList, handleEditClick, handleDeleteClick) {
  const columns = [
    { Header: "Name", accessor: "category", width: "45%", align: "left" },
    { Header: "Description", accessor: "description", align: "left" },
    { Header: "isPublished", accessor: "isPublished", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  const rows =
    categoryList?.results?.length > 0
      ? categoryList.results.map((item, index) => ({
          category: <Category image={item?.image[0]} name={item?.name || "-"} />,
          description: <CategoryDesc description={item.description || "-"} />,
          isPublished: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.isPublished ? "Published" : "Unpublished"}
            </MDTypography>
          ),
          action: (
            <MDTypography className="flex">
              <Icon
                className="text-black mr-1"
                icon="ep:edit"
                onClick={() => handleEditClick(item)}
              />
              <Icon
                className="text-red-800"
                icon="ep:delete"
                onClick={() => handleDeleteClick(item)}
              />
            </MDTypography>
          ),
        }))
      : [
          {
            category: (
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                style={{ textAlign: "center", padding: "20px" }}
                colSpan={columns.length}
              >
                No Data Found
              </MDTypography>
            ),
            description: "",
            isPublished: "",
            action: "",
          },
        ];

  return { columns, rows };
}

Category.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
};

CategoryDesc.propTypes = {
  description: PropTypes.string.isRequired,
};
