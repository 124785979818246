import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Grid, Button } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import cardData from "./data"; // Import the data from data.js
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LanguageIcon from "@mui/icons-material/Language";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const validateCardData = (card) => {
  const requiredFields = [
    "userName",
    "yajyaName",
    "location",
    "contactNumber",
    "timestamp",
    "date",
    "timeSlot",
    "preferredLanguage",
    "amount",
  ];

  return requiredFields.every((field) => card.hasOwnProperty(field) && card[field]);
};

const CurrentYagnaCard = ({ data }) => {
  const cardStyle = {
    width: "100%",
    backgroundColor: "#FFF6F0",
    boxShadow: "0 0.25em 0.5em rgba(0, 0, 0, 0.1)", // Converted from 4px 8px
    borderRadius: "0.75em", // Converted from 12px
    overflow: "hidden",
    marginBottom: "1.25em", // Converted from 20px
    padding: "1em", // Converted from 16px
  };

  const labelStyle = {
    fontWeight: "bold",
    marginRight: "0.5em", // Converted from 8px
    fontSize: "0.875em", // Converted from 14px
    color: "#344767",
  };

  const valueStyle = {
    marginBottom: "0.5em", // Converted from 8px
    fontSize: "0.700em", // Converted from 14px
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: "0.25em", // Converted from 4px
    color: "#344767",
  };

  const timestampStyle = {
    fontSize: "0.75em", // Converted from 12px
  };

  const buttonContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box display="flex" flexDirection="column" p={2}>
      {data && data.length > 0 ? (
        data.map((card, index) => {
          const estimReachTime = new Date(card?.estimReachTime);
          const formattedDate = estimReachTime.toLocaleDateString();
          const formattedTime = estimReachTime.toLocaleTimeString();
          return (
            <Card key={index} sx={cardStyle} mb={2}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={valueStyle}>
                      <PersonIcon style={iconStyle} />
                      <span style={labelStyle}>UserName:</span> {card?.orderedUserId?.firstName}
                      &nbsp;
                      {card?.orderedUserId?.lastName}
                    </Typography>
                    <Typography variant="body1" style={valueStyle}>
                      <EventIcon style={iconStyle} />
                      <span style={labelStyle}>Yajya Name:</span> {card?.planId?.name}
                    </Typography>
                    <Typography variant="body1" style={valueStyle}>
                      <LocationOnIcon style={iconStyle} />
                      <span style={labelStyle}>Location:</span> {card.addressId?.address},&nbsp;
                      {card.addressId?.landMark}
                    </Typography>
                    <Typography variant="body1" style={valueStyle}>
                      <PhoneIcon style={iconStyle} />
                      <span style={labelStyle}>Contact Number:</span> +
                      {card?.orderedUserId?.dialCode}
                      &nbsp;
                      {card?.orderedUserId?.phone}
                    </Typography>
                    <Typography variant="body1" style={valueStyle}>
                      <CurrencyRupeeIcon style={iconStyle} />
                      <span style={labelStyle}>Amount:</span> {card.amount}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={timestampStyle}>
                      {/* Added {formatDistanceToNow(new Date(card.timestamp))} ago */}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={valueStyle}>
                      <EventIcon style={iconStyle} />
                      <span style={labelStyle}>Date:</span> {formattedDate}
                    </Typography>
                    <Typography variant="body1" style={valueStyle}>
                      <AccessTimeIcon style={iconStyle} />
                      <span style={labelStyle}>Time:</span> {formattedTime}
                    </Typography>
                    <Typography variant="body1" style={valueStyle}>
                      <AccessTimeIcon style={iconStyle} />
                      <span style={labelStyle}>Duration:</span> {card.planId?.planDuration}&nbsp;hr
                    </Typography>
                    <Typography variant="body1" style={valueStyle}>
                      <LanguageIcon style={iconStyle} />
                      <span style={labelStyle}>Preferred Language:</span> {card.preferredLanguage}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Typography variant="h6" color="textSecondary" align="center">
          No yagna booked for today
        </Typography>
      )}
    </Box>
  );
};

export default CurrentYagnaCard;
