import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDelete(props) {
  const { open, fullMessage, handleClose, deleteHandler, title } = props;

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            // backgroundColor: "#FD8D4C",
            height: "325px",
          },
        }}
        className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur backdrop-filter bg-black bg-opacity-60 text-start"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle sx={{ paddingBottom: "0px", display: "flex", justifyContent: "flex-end" }}>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack justifyContent={"center"} alignItems={"center"} sx={{ padding: "5px 57px" }}>
            <Box
              sx={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                background: "#FD8D4C",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "2rem",
              }}
            >
              <DeleteForeverIcon sx={{ fontSize: "2rem", color: "#ffffff" }} />
            </Box>

            <Typography variant="h4" sx={{ textAlign: "center", marginTop: "25px" }}>
              {fullMessage}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingBottom: "30px" }}>
          <Button onClick={deleteHandler} variant="submit">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

// // eslint-disable-next-line react/prop-types
ConfirmDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  fullMessage: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
