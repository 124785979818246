import { apiSlice } from ".";
import URLS from "../constants/api";

export const notificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNotification: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${URLS.NOTIFICATION}`,
        method: "POST",
        body,
      }),
      providesTags: ["Notification"],
    }),
    sendNotification: builder.mutation({
      query: (id) => ({
        url: `${URLS.SEND_NOTIFICATION}?notificationId=${id}`,
        method: "POST",
      }),
      providesTags: ["Notification"],
    }),
    updateNotification: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${URLS.NOTIFICATION}?notificationId=${id}`,
        method: "PATCH",
        body,
      }),
      providesTags: ["Notification"],
    }),
    getNotification: builder.query({
      query: ({ sortBy, planId, search, page }) => {
        let queryString = `${URLS.NOTIFICATION}?sortBy=${sortBy}`;

        if (planId) {
          queryString += `&planId=${planId}`;
        }

        if (search) {
          queryString += `&search=${search}`;
        }

        return queryString;
      },
      providedTags: ["Notification"],
    }),

    deleteNotification: builder.mutation({
      query: (id) => ({
        url: `${URLS.NOTIFICATION}?notificationId=${id}`,
        method: "DELETE",
      }),
      providesTags: ["Notification"],
    }),
  }),
});

export const {
  useAddNotificationMutation,
  useUpdateNotificationMutation,
  useLazyGetNotificationQuery,
  useSendNotificationMutation,
  useDeleteNotificationMutation,
} = notificationApiSlice;
