import React, { useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import { Box, Grid, Card, Button, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import DataTable from "subComponents/Tables/DataTable";
import categoryTableData from "./data/categoryTableData";
import AddCategory from "../../addYajya/addCategory";
import ConfirmDelete from "../../../shared/deleteDialog";
import {
  useLazyGetCategoryQuery,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useAddYagnaCategoryMutation,
} from "../../../redux/api/categoryApi";

import useFilter from "../../../utility/useFilter";
import { useDebounce } from "@uidotdev/usehooks";
import { customToastStyle } from "../../../redux/api";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Category = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const categoryData = useSelector((state) => state?.category.category);
  const [categoryList, setCategoryList] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [image, setImage] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  const [getCategory] = useLazyGetCategoryQuery();
  const [deleteCategory, { isLoading: isDeleteCategoryLoading }] = useDeleteCategoryMutation();
  const [updateCategory, { isLoading: isUpdateCategoryLoading }] = useUpdateCategoryMutation();
  const [addYagnaCategory] = useAddYagnaCategoryMutation();
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const { filters, setFilters } = useFilter();

  const handleAction = (type, item = null) => {
    switch (type) {
      case "OPEN_DELETE":
        setSelectedCategoryId(item);
        setIsDeleteOpen(true);

        break;
      case "CLOSE_DELETE":
        setIsDeleteOpen(false);
        setSelectedCategoryId(null);
        break;
      case "CONFIRM_DELETE":
        if (selectedCategoryId) {
          handleDeleteCategory(selectedCategoryId);
        }
        setIsDeleteOpen(false);
        break;
      case "OPEN_ADD_CATEGORY":
        methods.reset();
        setAddCategory(true);
        break;
      case "CLOSE_ADD_CATEGORY":
        setAddCategory(false);
        methods.reset();
        setImage("");
        break;
      case "OPEN_EDIT_CATEGORY":
        setEditIndex(item);
        break;
      case "CLOSE_EDIT_CATEGORY":
        setEditIndex(null);
        break;

      default:
        console.warn("Unknown action type:", type);
    }
  };
  const handleChange = (e, p) => {
    setPage(p);
  };

  // const fetchCategoryData = useFetchData(getCategory, getCategoryAction);

  const handleDeleteCategory = async (id) => {
    try {
      const deleteCategoryResponse = await deleteCategory(id);
      if (!deleteCategoryResponse?.data?.success) {
        toast.error(`${deleteCategoryResponse?.data?.message || "Something went wrong!"} `, {
          style: customToastStyle.error,
        });
      }
    } catch (err) {
      console.error("Failed to delete category:", err);
    }
  };

  const handleSaveCategory = async (data) => {
    console.log(data, "data");
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (image) {
        console.log(image, "image");
        formData.append("image", image);
      }
      if (editIndex) {
        const updateCategoryResponse = await updateCategory({
          id: editIndex.id,
          formData,
        }).unwrap();
        if (!updateCategoryResponse?.success) {
          toast.error(`${updateCategoryResponse.data.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
      } else {
        const yagnaCategoryResponse = await addYagnaCategory(formData).unwrap();
        if (!yagnaCategoryResponse?.success) {
          toast.error(`${updateCategoryResponse?.data?.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
        const categoryData = await getCategory(filters);
        setCategoryList(categoryData?.data?.data);
      }
    } catch (err) {
      console.error("Failed to save category:", err);
    }
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      const categoryData = await getCategory(filters);
      if (categoryData?.data?.success) setCategoryList(categoryData?.data?.data);
    };
    fetchCategoryData();
  }, [filters, isDeleteCategoryLoading, isUpdateCategoryLoading, page]);
  const { columns, rows } = categoryTableData(
    categoryList,
    (item) => handleAction("OPEN_EDIT_CATEGORY", item),
    (item) => handleAction("OPEN_DELETE", item.id)
  );

  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, name: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, page]);

  return (
    <FormProvider {...methods}>
      <div className="flex justify-end">
        <Button variant="addButton" onClick={() => handleAction("OPEN_ADD_CATEGORY")}>
          Add Category
        </Button>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Yajya Category
                </MDTypography>
                <input
                  className="text-sm mr-2 p-2 text-gray-700 rounded-md w-56"
                  type="text"
                  placeholder="Search Here"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {(addCategory || editIndex) && (
        <AddCategory
          title={editIndex ? "Edit Category" : "Add Category"}
          image={image}
          open={addCategory || Boolean(editIndex)}
          onClose={() => handleAction(editIndex ? "CLOSE_EDIT_CATEGORY" : "CLOSE_ADD_CATEGORY")}
          onSubmit={handleSaveCategory}
          editData={editIndex}
        />
      )}
      {isDeleteOpen && (
        <ConfirmDelete
          title=""
          fullMessage="Are you sure you want to delete this category?"
          handleClose={() => handleAction("CLOSE_DELETE")}
          deleteHandler={() => handleAction("CONFIRM_DELETE")}
          open={isDeleteOpen}
        />
      )}
      {/* {editIndex !== null && (
        <EditDialog
          title="Edit Category"
          onClose={() => handleAction("CLOSE_EDIT_CATEGORY")}
          editData={editIndex}
          open={editIndex !== null}
          onSubmit={handleEditCategory}
        />
      )} */}

      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={categoryList?.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </FormProvider>
  );
};

export default Category;
