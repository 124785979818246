// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addSubCategory, getSubCategory } from "../reducers/subCategoryReducer";

const initialState = {
  subCategory: [],
  error: null,
};

const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    addSubCategory,
    getSubCategory,
  },
});

export const { addSubCategory: addSubCategoryAction, getSubCategory: getSubCategoryAction } =
  subCategorySlice.actions;
export default subCategorySlice.reducer;
