// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addSamagri, getSamagri } from "../reducers/samagriReducer";

const initialState = {
  subCategory: [],
  error: null,
};

const samagriSlice = createSlice({
  name: "samagri",
  initialState,
  reducers: {
    addSamagri,
    getSamagri,
  },
});

export const { addSamagri: addSamagriAction, getSamagri: getSamagriAction } = samagriSlice.actions;
export default samagriSlice.reducer;
