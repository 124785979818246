// notificationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addPanditOnboard } from "../reducers/panditOnboardReducer";

const initialState = {
  panditOnboard: [],
  error: null,
};

const panditOnboard = createSlice({
  name: "panditOnboard",
  initialState,
  reducers: {
    addPanditOnboard,
  },
});

export const { addPanditOnboard: addPanditOnboardAction } = panditOnboard.actions;
export default panditOnboard.reducer;
