import React, { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { TextField as MUITextField } from "@mui/material";
import colors from "../../theme/colors";

const TextFieldStyled = styled(MUITextField)(({ theme, bgcolor }) => ({
  "& .MuiInputLabel-root": {
    transform: "none",
    position: "relative",
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    textAlign: "left",
  },
  "& .MuiInputBase-root": {
    borderRadius: 4,
    backgroundColor: bgcolor || colors.white,
    border: `1px solid ${theme.palette.grey[400]}`,
    transition: theme.transitions.create(["border-color", "box-shadow"], {
      duration: theme.transitions.duration.shorter,
    }),
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused": {
      borderColor: "black", // Change the color to black
      boxShadow: "none", // Remove the box shadow
      outline: "none", // Remove the outline
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  "& .MuiInputBase-input": {
    color: theme.palette.text.primary,
    padding: "10px 12px",
    "&::placeholder": {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  },
  "& .MuiFormHelperText-root": {
    margin: theme.spacing(1, 0, 0),
    color: theme.palette.error.main,
    fontSize: theme.typography.caption.fontSize,
  },
}));

const TextField = forwardRef((props, ref) => {
  const { size = "small", bgcolor, ...rest } = props;

  return (
    <TextFieldStyled
      size={size}
      bgcolor={bgcolor}
      inputRef={ref}
      {...rest}
      variant="outlined"
      InputLabelProps={{ ...props, shrink: true }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              color: colors.white,
            },
          },
        },
      }}
    />
  );
});
TextField.displayName = "CustomTextField";
export default TextField;
