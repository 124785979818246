import { apiSlice } from ".";
import URLS from "../constants/api";

export const panditApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPandits: builder.query({
      query: ({ sortBy, role, firstName, page }) => {
        let queryString = `${URLS.PANDITS}?sortBy=${sortBy}`;
        if (role) {
          queryString += `&role=${role}`;
        }
        if (firstName) {
          queryString += `&firstName=${firstName}`;
        }
        if (page) {
          queryString += `&page=${page}`;
        }
        return queryString;
      },
      providesTags: ["PANDITS"],
    }),
    verifyDocument: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${URLS.VERIFY_PANDIT_DOCUMENT}?userId=${id}`,
        method: "POST",
        body,
      }),
      providesTags: ["VERIFYPANDITDOCUMENT"],
    }),
  }),
});

export const { useLazyGetPanditsQuery, useVerifyDocumentMutation } = panditApiSlice;
