// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getBooking } from "../reducers/bookingReducer";

const initialState = {
  booking: [],
  error: null,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    getBooking,
  },
});

export const { getBooking: getBookingAction } = bookingSlice.actions;
export default bookingSlice.reducer;
