const cardData = [
  {
    userName: "John Doe",
    yajyaName: "Yajya Ceremony",
    location: "Temple Hall",
    date: "2024-07-17",
    timeSlot: "10:00 AM - 12:00 PM",
    preferredLanguage: "Sanskrit",
    amount: "$500",
    contactNumber: "123-456-7890",
    timestamp: new Date("2024-07-17T14:00:00Z"), // Example timestamp
  },
  {
    userName: "Jane Smith",
    yajyaName: "Annual Ritual",
    location: "Community Center",
    date: "2024-07-17",
    timeSlot: "2:00 PM - 4:00 PM",
    preferredLanguage: "Hindi",
    amount: "$300",
    contactNumber: "987-654-3210",
    timestamp: new Date("2024-07-17T15:00:00Z"), // Example timestamp
  },
  {
    userName: "Robert Brown",
    yajyaName: "Special Puja",
    location: "Main Hall",
    date: "2024-07-17",
    timeSlot: "9:00 AM - 11:00 AM",
    preferredLanguage: "English",
    amount: "$400",
    contactNumber: "456-789-0123",
    timestamp: new Date("2024-07-17T16:00:00Z"), // Example timestamp
  },
  {
    userName: "Alice Johnson",
    yajyaName: "Peace Ceremony",
    location: "Garden Park",
    date: "2024-07-18",
    timeSlot: "3:00 PM - 5:00 PM",
    preferredLanguage: "Spanish",
    amount: "$600",
    contactNumber: "789-012-3456",
    timestamp: new Date("2024-07-17T17:00:00Z"), // Example timestamp
  },
  {
    userName: "Eva Martinez",
    yajyaName: "Harmony Ritual",
    location: "Riverfront Plaza",
    date: "2024-07-18",
    timeSlot: "11:00 AM - 1:00 PM",
    preferredLanguage: "French",
    amount: "$450",
    contactNumber: "234-567-8901",
    timestamp: new Date("2024-07-17T18:00:00Z"), // Example timestamp
  },
  {
    userName: "Michael White",
    yajyaName: "Blessings Ceremony",
    location: "Hilltop Mansion",
    date: "2024-07-18",
    timeSlot: "10:00 AM - 12:00 PM",
    preferredLanguage: "German",
    amount: "$700",
    contactNumber: "345-678-9012",
    timestamp: new Date("2024-07-17T19:00:00Z"), // Example timestamp
  },
];

export default cardData;
