import { useEffect } from "react";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebaseConfig";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setDeviceIdAction } from "../redux/slices/deviceTokenLocSlice";

const useRequestPermission = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const requestPermission = async () => {
      // Requesting permission using Notification API
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        try {
          const token = await getToken(messaging, {
            vapidKey:
              process.env.REACT_APP_FIREBASE_WEB_PUSH_VAPID_KEY ||
              "BO7kPEdk0_cbN4XckH5XrLffYWuySjZj-7izHzqNDvrrsllUl30Z3-KtnQ3CJmgQKM12I-XkiAvrEedtQrIaTxM",
          });

          if (token) {
            dispatch(setDeviceIdAction(token));
            localStorage.setItem("deviceId", token);
          }
        } catch (error) {
          console.error("Error generating token: ", error);
        }
      } else {
        // If permission is denied or dismissed
        dispatch(setDeviceIdAction(null));
        localStorage.removeItem("deviceId");
        if (permission === "denied") {
          toast.error("You denied the notification permission");
        }
      }
    };

    // const checkPermissionStatus = () => {
    //   if (Notification.permission !== "granted") {
    //     dispatch(setDeviceIdAction(null));
    //     localStorage.removeItem("deviceId");
    //   }
    // };

    // Request permission on mount
    requestPermission();

    // // Check permission status whenever the component is mounted
    // checkPermissionStatus();

    // const intervalId = setInterval(checkPermissionStatus, 60000); // Check every 60 seconds

    // return () => clearInterval(intervalId);
  }, [dispatch]);
};

export default useRequestPermission;
