import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Typography } from "@mui/material";

import { Icon } from "@iconify/react";
import { useAddWalletPayoutMutation } from "../../../../../redux/api/walletTransactionApi";
import toast, { Toaster } from "react-hot-toast";
import { customToastStyle } from "../../../../../redux/api";

const TransactionDialog = ({ setIsApprove, transactionId }) => {
  const [addWalletPayout] = useAddWalletPayoutMutation();

  const handleApproveClick = async () => {
    try {
      const walletPayoutResponse = await addWalletPayout({ transactionId: transactionId });
      if (!walletPayoutResponse?.data?.success) {
        toast.error(`${walletPayoutResponse?.data?.message || "Something went wrong!"} `, {
          style: customToastStyle.error,
        });
      }
      setIsApprove(false);
    } catch (err) {
      console.error("Failed to approve wallet payout:", err);
    }
    setIsApprove(false);
  };
  const handleCancelClick = () => {
    setIsApprove(false);
  };
  return (
    <Dialog open={open}>
      <DialogTitle sx={{ color: "#FD8D4C", display: "flex", justifyContent: "space-between" }}>
        <h1>Approve Transaction</h1>
        <span onClick={handleCancelClick} className="text-black mt-1">
          <Icon className="h-6 w-6" icon="ic:baseline-close" />
        </span>
      </DialogTitle>
      <hr />

      <DialogContent>
        {/* <Typography variant="body1" sx={{ width: "400px" }}>
          <span className="flex justify-center">
            Are you sure you want to approve transaction ?
          </span>
        </Typography> */}
        <Typography
          variant="h5"
          sx={{ textAlign: "center", width: "300px", paddingX: "10px", marginTop: "15px" }}
        >
          Are you sure you want to approve transaction ?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          marginTop: "15px",
        }}
      >
        <Button
          onClick={handleCancelClick}
          // sx={{ color: "#ffffff", backgroundColor: "#FD8D4C", marginTop: "10px" }}
          variant="submit"
        >
          Cancel
        </Button>
        {/* <Button
          onClick={handleCancelClick}
          sx={{
            color: "#ffffff",
            backgroundColor: "#FD8D4C",
            height: "30px",
            minHeight: "30px",
            maxHeight: "30px",
            width: "60px",
            minWidth: "60px",
            maxWidth: "60px",
            fontSize: "12px",
            padding: 0, // This will ensure there's no extra space inside the button
          }}
        >
          Cancel
        </Button> */}
        {/* <Button
          onClick={handleApproveClick}
          type="submit"
          sx={{
            color: "#ffffff",
            backgroundColor: "#FD8D4C",
            height: "30px",
            minHeight: "30px",
            maxHeight: "30px",
            width: "70px",
            minWidth: "70px",
            maxWidth: "70px",
            fontSize: "12px",
            padding: 0, // This will ensure there's no extra space inside the button
          }}
        >
          Approve
        </Button> */}
        <Button
          onClick={handleApproveClick}
          // sx={{ color: "#ffffff", backgroundColor: "#FD8D4C", marginTop: "10px" }}
          variant="submit"
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// AddBanner.propTypes = {
//   image: PropTypes.string,
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   handleInputChange: PropTypes.func.isRequired,
// };

export default TransactionDialog;
