// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addBanner, getBanner, deleteBanner } from "../reducers/bannerReducer";

const initialState = {
  banners: [],
  error: null,
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    addBanner,
    getBanner,
    deleteBanner,
  },
});

export const {
  addBanner: addBannerAction,
  getBanner: getBannerAction,
  deleteBanner: deleteBannerAction,
} = bannerSlice.actions;
export default bannerSlice.reducer;
