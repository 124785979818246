import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import RHFTextField from "../../hook-form/RHFTextField";

import { Icon } from "@iconify/react";
import { useAddBannerMutation, useLazyGetBannerQuery } from "../../redux/api/bannerApi";
import RHFImageUpload from "../../hook-form/RHFImageUpload";
import { useDispatch, useSelector } from "react-redux";
import { addBannerAction, getBannerAction } from "../../redux/slices/bannerSlice";
import { RHFSelect } from "hook-form/RHFSelect";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useFetchData from "../../utility/useFetchData";
import { getCategoryAction } from "../../redux/slices/categorySlice";
import { useLazyGetCategoryQuery } from "../../redux/api/categoryApi";
import useFetchCategory from "../../utility/useFetchCategory";
import { Height, WidthFull } from "@mui/icons-material";
import useFilter from "utility/useFilter";

const AddBanner = ({ onClose, open, setBannerList }) => {
  const { register, handleSubmit } = useFormContext();
  const dispatch = useDispatch();
  const [addBanner] = useAddBannerMutation();
  const [getBanner] = useLazyGetBannerQuery();
  const [isToggled, setIsToggled] = useState(false);
  const [getCategory] = useLazyGetCategoryQuery();

  const planOptions = useFetchCategory();

  const { filters } = useFilter();

  const handleSubmitClick = async (data) => {
    const formData = new FormData();
    const banner = {
      name: data.name || "",
      image: data.image || "",
      plan: data.plan_category || "",
      description: data.description || "",
      isActive: isToggled || false,
    };
    Object.entries(banner).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await addBanner(formData).unwrap();
    if (response) {
      const bannerData = await getBanner(filters);
      if (bannerData?.data?.success) {
        setBannerList(bannerData?.data?.data);
      }
      onClose();
    }
  };
  const handleToggle = () => setIsToggled(!isToggled);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="w-96">
        <DialogTitle sx={{ color: "#FD8D4C" }}>Add Banner</DialogTitle>
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <DialogContent>
            <RHFImageUpload name="image" label="Upload Image" required />
            <RHFSelect
              name="plan_category"
              options={planOptions}
              sx={{ marginBottom: "10px", Height: "auto", width: "auto" }}
              required
            />
            <RHFTextField
              name="name"
              placeholder="Enter feature name"
              type="text"
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Description"
              name="description"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              required
              {...register("description")}
            />
            <MDBox
              className="flex justify-between"
              {...register("publish")}
              display="flex"
              alignItems="center"
            >
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleToggle}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;publish :
              </MDTypography>
              <Switch
                checked={isToggled}
                onChange={handleToggle}
                sx={{
                  "& .MuiSwitch-track": {
                    backgroundColor: isToggled ? "green" : "red",
                  },
                }}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button variant="submit" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="submit" type="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
};

AddBanner.propTypes = {
  image: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AddBanner;
