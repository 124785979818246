import { apiSlice } from ".";
import URLS from "../constants/api";

export const samagriApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSamagri: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${URLS.SAMAGRI}`,
        method: "POST",
        body,
      }),
      providesTags: ["Samagri"],
    }),
    updateSamagri: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${URLS.SAMAGRI}?samgiriId=${id}`,
        method: "PATCH",
        body,
      }),
      providesTags: ["Samagri"],
    }),
    getSamagri: builder.query({
      query: ({ sortBy, name, page }) => {
        let queryString = `${URLS.SAMAGRI}?sortBy=${sortBy}`;
        if (name) {
          queryString += `&name=${name}`;
        }
        if (page) {
          queryString += `&page=${page}`;
        }
        return queryString;
      },
      providedTags: ["Samagri"],
    }),

    deleteSamagri: builder.mutation({
      query: (id) => ({
        url: `${URLS.SAMAGRI}?samgiriId=${id}`,
        method: "DELETE",
      }),
      providesTags: ["Samagri"],
    }),
  }),
});

export const {
  useAddSamagriMutation,
  useUpdateSamagriMutation,
  useLazyGetSamagriQuery,
  useDeleteSamagriMutation,
} = samagriApiSlice;
