// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { signinUser } from "../actions/signinActions";

const initialState = {
  email: null,
  loading: false,
  userInfo: null,
  userToken: null,
  userTokenExpires: null,
  error: null,
  success: false,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.email = null;
      state.userInfo = null;
      state.userToken = null;
      state.userTokenExpires = null;
      state.success = false;
      state.isAuthenticated = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accessTokenExpires");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signinUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signinUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.userInfo = payload?.data?.user;
        state.userToken = payload?.data?.tokens?.access?.token;
        state.userTokenExpires = payload?.data?.tokens?.access?.expires;
        state.isAuthenticated = true;

        localStorage.setItem("accessToken", state?.userToken);
        localStorage.setItem("accessTokenExpires", state?.userTokenExpires);
      })
      .addCase(signinUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isAuthenticated = false;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
